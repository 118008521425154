import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subject, firstValueFrom } from 'rxjs';
import { SelectionService } from '../../services/selection.service';
import { environment } from 'projects/phone/src/environments/environment';
import {
  TenantActionsLatestQueryArgs,
  TenantActionsLatestQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import {
  ActivePlansQueryArgs,
  ActivePlansQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/plan';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { FULL_FRAGMENT_PLAN_STEP } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStep';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { ActionTypeOutput } from 'projects/shared/src/lib/graphql/output/actionTypeOutput';
import { ActionFrom } from 'projects/shared/src/lib/graphql/enums/actionFrom';
import { ActionTo } from 'projects/shared/src/lib/graphql/enums/actionTo';
import { UserSelectService } from 'projects/shared/src/lib/services/user-select.service';
import { LocationsQueryRoot } from 'projects/shared/src/lib/graphql/crud/location';
import { FULL_FRAGMENT_LOCATION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentLocation';
import { ActionType } from 'projects/shared/src/lib/graphql/enums/actionType';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';

@Injectable()
export class NgScanAssetFactory {
  constructor(
    public apollo: Apollo,
    public http: HttpClient,
    public selectionService: SelectionService
  ) {}

  create(id: string): NgScanAsset {
    return new NgScanAsset(this, id);
  }
}

export class NgScanAsset {
  evaluationFinished = new Subject<'plan' | 'realtime' | 'unknown'>();
  asset: any;
  action: TenantActionOutput | undefined;
  plans: PlanOutput[] = [];

  #evaluationState: 'not-started' | 'evaluating' | 'finished' = 'not-started';

  constructor(private factory: NgScanAssetFactory, public id: string) {}

  async evaluate(start: Date, end: Date) {
    // if (this.#evaluationState !== 'not-started') {
    //   return;
    // }

    // Do the evaluation:
    // 1. Load asset data.
    // 2. Load latest action data.
    // 3. Load relevant plans.
    try {
      this.#evaluationState = 'evaluating';

      this.asset = await this.#loadAsset(this.id);
      this.action = await this.#loadLatestAction(this.id);
      this.plans = await this.#loadActivePlans(this.id, start, end);
    } catch (error) {
      console.log(error);
    } finally {
      this.#evaluationState = 'finished';
    }

    this.evaluationFinished.next('plan');
    this.evaluationFinished.complete();
  }

  async #loadAsset(tenantAssetId: string): Promise<any> {
    const url =
      environment.apiBaseUrl +
      '/api/assets/byId/' +
      this.factory.selectionService.selectedTenant?.id;
    const body = {
      assetIds: [tenantAssetId],
    };

    const assets = await firstValueFrom(this.factory.http.post<any[]>(url, body));
    return assets[0];
  }

  async #loadLatestAction(tenantAssetId: string): Promise<TenantActionOutput> {
    const variables: TenantActionsLatestQueryArgs = {
      assetIds: [tenantAssetId],
    };

    const result = await firstValueFrom(
      this.factory.apollo.query<TenantActionsLatestQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_TENANT_ACTION}
          query TenantActionsLatest($assetIds: [String!]!) {
            tenantActionsLatest(assetIds: $assetIds) {
              ...FullFragmentTenantAction
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    return result.data.tenantActionsLatest[0];
  }

  async #loadActivePlans(
    tenantAssetId: string,
    startDate: Date,
    endDate: Date
  ): Promise<PlanOutput[]> {
    const variables: ActivePlansQueryArgs = {
      assetIds: [tenantAssetId],
      startDate,
      endDate,
    };

    const result = await firstValueFrom(
      this.factory.apollo.query<ActivePlansQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_PLAN}
          ${FULL_FRAGMENT_PLAN_STEP}
          ${FULL_FRAGMENT_PLAN_STEP_ACTION}
          query ActivePlans($assetIds: [String!], $startDate: DateTime!, $endDate: DateTime!) {
            activePlans(assetIds: $assetIds, startDate: $startDate, endDate: $endDate) {
              ...FullFragmentPlan
              planSteps {
                ...FullFragmentPlanStep
                planStepActions {
                  ...FullFragmentPlanStepAction
                  actionType {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );
    return result.data.activePlans;
  }
}

@Injectable()
export class NgScanRealtimeConfigFactory {
  constructor(
    public userSelectService: UserSelectService,
    public apollo: Apollo,
    public assortmentService: AssortmentService
  ) {}

  create(): NgScanRealtimeConfig {
    return new NgScanRealtimeConfig(this);
  }
}

export class NgScanRealtimeConfig {
  validNextActions: [ActionType, ActionFrom, string][] = [];

  selectedActionType: ActionTypeOutput | undefined;
  selectedFromType: ActionFrom | undefined;
  selectedToType: ActionTo | undefined;
  description: string | undefined;

  get validNextActionTypes(): ActionTypeOutput[] {
    return this.factory.assortmentService.actionTypes.filter((x) =>
      this.validNextActions.map((x) => x[0]).includes(x.id)
    );
  }

  get fromDetails(): string | undefined {
    return this.#fromDetails;
  }
  set fromDetails(value) {
    this.#fromDetails = value;
    if (this.selectedFromType === ActionFrom.User) {
      this.#getUserData(value, 'from');
    } else if (this.selectedFromType === ActionFrom.Location) {
      this.#getLocationData(value, 'from');
    }
  }

  get fromDetailsCalculated(): string | undefined {
    if (
      !this.selectedFromType ||
      [ActionFrom.Mail, ActionFrom.Other].includes(this.selectedFromType)
    ) {
      return this.fromDetails;
    }

    return this.#fromDetailsCalculated;
  }

  get toDetails(): string | undefined {
    return this.#toDetails;
  }
  set toDetails(value) {
    this.#toDetails = value;
    if (this.selectedToType === ActionTo.User) {
      this.#getUserData(value, 'to');
    } else if (this.selectedToType === ActionTo.Location) {
      this.#getLocationData(value, 'to');
    }
  }

  get toDetailsCalculated(): string | undefined {
    if (!this.selectedToType || [ActionTo.Mail, ActionTo.Other].includes(this.selectedToType)) {
      return this.toDetails;
    }

    return this.#toDetailsCalculated;
  }

  #fromDetails: string | undefined;
  #fromDetailsCalculated: string | undefined;
  #toDetails: string | undefined;
  #toDetailsCalculated: string | undefined;

  constructor(public factory: NgScanRealtimeConfigFactory) {}

  async #getUserData(oId: string | undefined, setFromOrTo: 'from' | 'to') {
    if (!oId) {
      return;
    }

    const oUser = await this.factory.userSelectService.getOUserByOId(oId);
    if (!oUser || !oUser.displayName) {
      return;
    }

    switch (setFromOrTo) {
      case 'from':
        this.#fromDetailsCalculated = oUser.displayName;
        break;

      case 'to':
        this.#toDetailsCalculated = oUser.displayName;
        break;

      default:
        break;
    }
  }

  async #getLocationData(locationId: string | undefined, setFromOrTo: 'from' | 'to') {
    if (!locationId) {
      return;
    }

    const result = await firstValueFrom(
      this.factory.apollo.query<LocationsQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_LOCATION}
          query Locations {
            locations {
              ...FullFragmentLocation
            }
          }
        `,
        fetchPolicy: 'cache-first',
      })
    );

    const location = result.data.locations.find((x) => x.id === locationId);

    let locationString = location?.level1;
    if (location?.level2) {
      locationString += ', ' + location.level2;
      if (location.level3) {
        locationString += ', ' + location.level3;
        if (location.level4) {
          locationString += ',' + location.level4;
          if (location.level5) {
            locationString += ', ' + location.level5;
          }
        }
      }
    }

    switch (setFromOrTo) {
      case 'from':
        this.#fromDetailsCalculated = locationString;
        break;

      case 'to':
        this.#toDetailsCalculated = locationString;
        break;

      default:
        break;
    }
  }
}
