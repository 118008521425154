import { Component } from '@angular/core';
import { SelectionService } from '../../services/selection.service';
import { MatDialog } from '@angular/material/dialog';
import { SwitchTenantDialogComponent } from '../../component-dialogs/switch-tenant-dialog/switch-tenant-dialog.component';
import { TenantOutput } from 'projects/shared/src/lib/graphql/output/tenantOutput';
import { UserConfigService } from 'projects/shared/src/lib/services/user-config.service';
import { Router } from '@angular/router';
import { MtxDrawerRef } from '@ng-matero/extensions/drawer';
import packageJson from '../../../../../../package.json';
import { MsalService } from '@azure/msal-angular';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  year = new Date().toISOString().slice(0, 5);
  version = packageJson.custom.phone.version;

  constructor(
    private _drawerRef: MtxDrawerRef<MenuComponent>,
    public selectionService: SelectionService,
    private _matDialog: MatDialog,
    private _userConfigService: UserConfigService,
    private _router: Router,
    private _msalService: MsalService,
    private assetService: AssetService
  ) {}

  onClickSwitchTenant() {
    const result = this._matDialog.open(SwitchTenantDialogComponent);
    this._drawerRef.dismiss();

    result.afterClosed().subscribe((tenant: TenantOutput | undefined) => {
      if (!tenant) {
        return;
      }

      this._userConfigService.updateAsync({
        mTenantId: tenant.id,
      });
      this.selectionService.selectedTenant = tenant;
      this.assetService.setTenantId(tenant.id);
      this._router.navigateByUrl('/home');
    });
  }

  onClickSignOut() {
    this._msalService.logout();
    this._drawerRef.dismiss();
  }

  dismiss() {
    this._drawerRef.dismiss();
  }
}
