import { gql } from 'apollo-angular';

export const FULL_FRAGMENT_PLANNING_ACTION = gql`
  fragment FullFragmentPlanningAction on PlanningActionOutput {
    planStepActionId
    planStepId
    planId
    actionCompleted
    actionDate
    actionOrder
  }
`;
