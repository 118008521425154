import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-expansion-tile',
  templateUrl: './expansion-tile.component.html',
  styleUrls: ['./expansion-tile.component.scss'],
})
export class ExpansionTileComponent {
  @Input() isExpanded: boolean = false;
  @Input() backgroundColor: string | undefined | null;
  @Output() isExpandedChange = new EventEmitter<boolean>();

  onClickExpand() {
    this.isExpanded = true;
    this.isExpandedChange.emit(true);
  }

  onClickShrink() {
    this.isExpanded = false;
    this.isExpandedChange.emit(false);
  }
}
