<mat-dialog-content>
  <div class="flex-column gap-tiny form-field-density-2">
    <!-- FROM -->
    <ng-container *ngIf="data.direction === 'from'">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>From</mat-label>
        <mat-select [(ngModel)]="selectedFromType">
          <mat-option
            *ngFor="let from of fromTypes | keyvalue"
            [value]="from.key"
          >
            {{ from.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="(selectedFromType ?? 0) > 2">
        <mat-form-field
          class="mt-small"
          subscriptSizing="dynamic"
          appearance="outline"
        >
          <mat-label> From Details </mat-label>
          <input
            matInput
            [(ngModel)]="fromDetails"
            [autocomplete]="uuid1"
            required
            [disabled]="!selectedFromType"
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="selectedFromType === 1">
        <lib-location-select
          #fromLocationSelect
          class="mt-small"
          label="From Details"
          appearance="outline"
          [required]="true"
          [(locationId)]="fromDetails"
        >
        </lib-location-select>
      </ng-container>

      <ng-container *ngIf="selectedFromType === 2">
        <div class="mt-small flex-row align-items-center gap-small">
          <lib-user-select
            #fromUserSelect
            class="flex-grow-1"
            appearance="outline"
            label="From Details"
            [required]="true"
            [(oId)]="fromDetails"
            [tenantUsersOnly]="true"
          ></lib-user-select>
          <button mat-icon-button (click)="me('from')">
            <span class="material-symbols-outlined"> frame_person </span>
          </button>
        </div>
      </ng-container>
    </ng-container>

    <!-- TO -->
    <ng-container *ngIf="data.direction === 'to'">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>To</mat-label>
        <mat-select [(ngModel)]="selectedToType">
          <mat-option *ngFor="let to of toTypes | keyvalue" [value]="to.key">
            {{ to.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="(selectedToType ?? 0) > 2">
        <mat-form-field
          class="mt-small"
          subscriptSizing="dynamic"
          appearance="outline"
        >
          <mat-label> To Details </mat-label>
          <input
            matInput
            [(ngModel)]="toDetails"
            [autocomplete]="uuid2"
            required
            [disabled]="!selectedToType"
          />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="selectedToType === 1">
        <lib-location-select
          #toLocationSelect
          class="mt-small"
          label="To Details"
          appearance="outline"
          [required]="true"
          [(locationId)]="toDetails"
        >
        </lib-location-select>
      </ng-container>

      <ng-container *ngIf="selectedToType === 2">
        <div class="mt-small flex-row align-items-center gap-small">
          <lib-user-select
            #toUserSelect
            class="flex-grow-1"
            appearance="outline"
            label="To Details"
            [required]="true"
            [(oId)]="toDetails"
            [tenantUsersOnly]="true"
          ></lib-user-select>
          <button mat-icon-button (click)="me('to')">
            <span class="material-symbols-outlined"> frame_person </span>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>CANCEL</button>
  <button
    mat-flat-button
    color="primary"
    (click)="onClickOk()"
    [disabled]="
      (data.direction === 'from' && !fromDetails) ||
      (data.direction === 'to' && !toDetails)
    "
  >
    OK
  </button>
</mat-dialog-actions>
