import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface AssetBaseInfoDialogData {
  confidentiality: string;
  owner: string;
  assetId: string;
  assetName: string;
}

@Component({
  selector: 'app-asset-base-info-dialog',
  templateUrl: './asset-base-info-dialog.component.html',
  styleUrls: ['./asset-base-info-dialog.component.scss']
})
export class AssetBaseInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AssetBaseInfoDialogData) {
  }
}
