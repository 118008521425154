import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { HomeComponent } from './components/home/home.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { TestComponent } from './components/test/test.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { LoginComponent } from './components/login/login.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { DefectComponent } from './components/defect/defect.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import {AssetsScanInfoComponent} from "./components/assets-scan-info/assets-scan-info.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: '',
    component: LandingComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'qrcodes',
        component: TestComponent,
      },
      {
        path: 'scanner',
        component: ScannerComponent,
      },
      {
        path: 'defects',
        component: DefectComponent,
      },
      {
        path: 'inventory',
        component: InventoryComponent,
      },
      {
        path: 'assetScan',
        component: AssetsScanInfoComponent,
      },
    ],
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
  },
  {
    path: 'privacy-statement',
    component: PrivacyStatementComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
