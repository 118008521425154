import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type NgScanNotesDialogData = {
  notes: string | undefined;
};

export type NgScanNotesDialogResult = {
  notes: string | undefined;
};

@Component({
  selector: 'app-ng-scan-notes-dialog',
  templateUrl: './ng-scan-notes-dialog.component.html',
  styleUrls: ['./ng-scan-notes-dialog.component.scss'],
})
export class NgScanNotesDialogComponent implements OnInit {
  notes: string | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NgScanNotesDialogData,
    private _dialogRef: MatDialogRef<NgScanNotesDialogComponent>
  ) {}

  ngOnInit(): void {
    this.notes = this.data.notes;
  }

  onClickOk() {
    const result: NgScanNotesDialogResult = {
      notes: this.notes,
    };
    this._dialogRef.close(result);
  }
}
