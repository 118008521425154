import { Component, Input } from '@angular/core';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';

@Component({
  selector: 'app-action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.scss'],
})
export class ActionDetailsComponent {
  @Input({ required: true }) fromOrTo: 'from' | 'to' | undefined;
  @Input() action: PlanStepActionOutput | undefined;
  @Input() variant: 'desktop' | 'phone' = 'desktop';
}
