<h2 mat-dialog-title>{{ data.title ?? 'Confirm' }}</h2>

<mat-dialog-content>
    <div class="flex-column">
        <div [innerHTML]="data.text" class="mt-small"></div>
        <div class="error-message">
            {{errorMessage}}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button (click)="cancel()" cdkFocusInitial>No</button>

    <button mat-button color="warn" [disabled]="activity" (click)="ok()">
        <span *ngIf="!activity">Yes</span>
        <mat-icon *ngIf="activity" class="spin">donut_large</mat-icon>
    </button>
</mat-dialog-actions>