<mat-form-field
  subscriptSizing="dynamic"
  appearance="outline"
  class="mt-normal padding-content"
>
  <input matInput [(ngModel)]="text" [autocomplete]="uuid1" />
</mat-form-field>

<div *ngFor="let qrCode of qrCodes" class="align-self-center">
  <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
</div>
