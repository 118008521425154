import { Component, OnInit, OnDestroy } from '@angular/core';
import { InitialLoadingService } from '../../services/initial-loading.service';
import 'projects/shared/src/lib/extensions/array';
import * as Sentry from '@sentry/angular-ivy';
import { MsalService } from '@azure/msal-angular';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import { environment } from 'projects/phone/src/environments/environment';
import { SelectionService } from '../../services/selection.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  constructor(
    public initialLoadingService: InitialLoadingService,
    private msalService: MsalService,
    private assetService: AssetService,
    private selectionService: SelectionService
  ) {}

  ngOnInit(): void {
    const account = this.msalService.instance.getAllAccounts()[0];
    Sentry.setUser({ username: account.name });
    this.initialLoadingService.loadInitialDataAsync().then(() => {
      this.assetService.setApiEndpoint(environment.apiBaseUrl + '/api/assets/byId/');
      this.assetService.setTenantId(this.selectionService.selectedTenant?.id ?? 'na');
    });
  }

  ngOnDestroy(): void {}
}
