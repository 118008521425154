import { Component } from '@angular/core';

@Component({
  selector: 'app-expansion-tile-header',
  templateUrl: './expansion-tile-header.component.html',
  styleUrls: ['./expansion-tile-header.component.scss']
})
export class ExpansionTileHeaderComponent {

}
