import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss'],
})
export class PrivacyStatementComponent {
  constructor(private _location: Location) {}

  onClickBack() {
    this._location.back();
  }
}
