<mat-dialog-content>
  <div class="flex-column gap-normal">
    <div class="form-field-density-2">
      <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <mat-label>Asset ID</mat-label>
        <input
          matInput
          type="text"
          [autocomplete]="uuid1"
          [(ngModel)]="assetId"
          (keydown.enter)="onClickApply()"
        />
      </mat-form-field>
    </div>

    <button
      mat-flat-button
      (click)="onClickApply()"
      [disabled]="!assetId"
      color="primary"
    >
      ADD
    </button>

    <div style="height: 4px"></div>
  </div>
</mat-dialog-content>
