<div class="banner" [ngClass]="isError ? 'red' : 'green'">
  <span *ngIf="isError" class="material-icons icon mt-large mb-large">
    highlight_off
  </span>
  <span *ngIf="isSuccess" class="material-icons icon mt-large mb-large">
    task_alt
  </span>
</div>

<div class="content">
  <span class="mt-large mb-normal align-self-center type-header">
    {{ typeText }}
  </span>
  <span class="text" [innerHTML]="data.text"></span>
  <button
    mat-stroked-button
    mat-dialog-close
    class="mt-normal mb-normal align-self-center"
  >
    Close
  </button>
</div>
