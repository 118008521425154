import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
  ScannerQRCodeResult
} from "ngx-scanner-qrcode";
import {LOCAL_STORAGE} from "../../common/localStorage";
import {Apollo, gql} from "apollo-angular";
import {LocaleService} from "../../../../../shared/src/lib/services/locale.service";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDialog} from "@angular/material/dialog";
import {AssetService} from "../../../../../shared/src/lib/services/asset.service";
import {PhoneToastService} from "../../services/phone-toast.service";
import {Location} from "@angular/common";
import {
  NgScanKeyboardDialogComponent,
  NgScanKeyboardDialogResult
} from "../../component-helpers/ng-scan/ng-scan-keyboard-dialog/ng-scan-keyboard-dialog.component";
import {
  PhoneToastDialogType
} from "../../component-dialogs/phone-toast-dialog/phone-toast-dialog.component";
import {CatchError} from "../../../../../shared/src/lib/classes/catch-error";
import {
  AssetColumnService,
  AssetPropertyInfo
} from "../../../../../shared/src/lib/services/asset-column.service";
import {SelectionService} from "../../services/selection.service";

@Component({
  selector: 'app-assets-scan-info',
  templateUrl: './assets-scan-info.component.html',
  styleUrls: ['./assets-scan-info.component.scss']
})
export class AssetsScanInfoComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('scanner') scanner!: NgxScannerQrcodeComponent;
  devices: ScannerQRCodeDevice[] = [];
  cameraIsOn = true;
  currentAsset: any
  properties: Map<string, AssetPropertyInfo> = new Map<string, AssetPropertyInfo>();
  uuidNameMap: Map<string, string> = new Map<string, string>();

  get selectedDevice() {
    return this.#selectedDevice;
  }

  set selectedDevice(value: ScannerQRCodeDevice | undefined) {
    this.#selectedDevice = value;
    if (value) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_DEVICE_ID, value.deviceId);
      this.scanner.vibrate = 0;
      this.scanner.isBeep = false;
      this.scanner.playDevice(value.deviceId);
    }
  }

  #selectedDevice: ScannerQRCodeDevice | undefined;
  #lastSuccessfulAssetIdScan: string | undefined;
  activity: boolean = false

  constructor(
    private apollo: Apollo,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private matDialog: MatDialog,
    private assetService: AssetService,
    private toastService: PhoneToastService,
    private location: Location,
    private assetColumnService: AssetColumnService,
    private selectionService: SelectionService
  ) {
  }

  async ngOnInit() {
    const tenantId = this.selectionService.selectedTenant?.id ?? 'na';
    this.properties = await this.assetColumnService.getColumns(tenantId);
    for (let value of this.properties.values()) {
      this.uuidNameMap.set(value.id, value.name);
    }
  }

  ngAfterViewInit(): void {
    this.#startCamera();
  }

  ngOnDestroy(): void {
    this.scanner.stop();
  }

  toggleCamera() {
    if (this.cameraIsOn) {
      this.scanner.stop();
    } else {
      this.#startCamera();
      this.#lastSuccessfulAssetIdScan = '';
      this.currentAsset = undefined;
    }

    this.cameraIsOn = !this.cameraIsOn;
    (document.activeElement as HTMLElement)?.blur();
  }

  onClickClose() {
    this.location.back();
  }

  async onEventCode(events: ScannerQRCodeResult[]) {
    for (const id of events.map((x) => x.value)) {
      if (id === this.#lastSuccessfulAssetIdScan || this.activity) {
        continue; // Avoid multiple reloads due to continues scanning.
      }

      this.#handleAssetId(id);
    }
  }

  onClickKeyboard() {
    const dialog = this.matDialog.open(NgScanKeyboardDialogComponent);

    dialog.afterClosed().subscribe((result: NgScanKeyboardDialogResult) => {
      if (!result) {
        return;
      }

      (document.activeElement as HTMLElement)?.blur();

      this.#handleAssetId(result);
    });
  }

  #startCamera() {
    const savedSelectedDeviceId = localStorage.getItem(LOCAL_STORAGE.SELECTED_DEVICE_ID);

    this.scanner.start((devices: ScannerQRCodeDevice[] | undefined) => {
      if (typeof devices === 'undefined' || devices.length === 0) {
        return;
      }

      this.devices = devices;

      if (savedSelectedDeviceId) {
        this.selectedDevice = this.devices.find((x) => x.deviceId === savedSelectedDeviceId);
      } else {
        // First run. Chose the first device.
        this.selectedDevice = this.devices[0];
      }
    });
  }

  async #handleAssetId(assetId: string) {
    try {
      this.activity = true;
      this.currentAsset = await this.assetService.fetch(assetId)
      this.#lastSuccessfulAssetIdScan = assetId;

      if (this.cameraIsOn) {
        this.toggleCamera()
      }
    } catch (error) {
      this.toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    } finally {
      this.activity = false;
    }
  }

  getNameForKey(key: unknown) {
    if (typeof key !== 'string') {
      return key;
    }

    return this.uuidNameMap.get(key) ?? key;
  }
}
