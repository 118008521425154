import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum PhoneToastDialogType {
  Error,
  Success,
}

export type PhoneToastDialogData = {
  type: PhoneToastDialogType;
  text: string;
};

@Component({
  selector: 'app-error-toast-dialog',
  templateUrl: './phone-toast-dialog.component.html',
  styleUrls: ['./phone-toast-dialog.component.scss'],
})
export class PhoneToastDialogComponent {
  get isError(): boolean {
    return this.data.type === PhoneToastDialogType.Error;
  }

  get isSuccess(): boolean {
    return this.data.type === PhoneToastDialogType.Success;
  }

  get typeText(): string {
    return this.data.type === PhoneToastDialogType.Error ? 'ERROR' : 'SUCCESS';
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: PhoneToastDialogData) {}
}
