<div
  class="expansion-tile"
  [style]="{ backgroundColor: backgroundColor ?? '#00000017' }"
>
  <div class="header">
    <ng-content select="app-expansion-tile-header"></ng-content>
  </div>

  <div class="content">
    <ng-content
      *ngIf="isExpanded"
      select="app-expansion-tile-content"
    ></ng-content>
  </div>

  <div class="footer">
    <div *ngIf="!isExpanded" class="flex-grow-1">
      <ng-content select="app-expansion-tile-footer"></ng-content>
    </div>
    <button
      *ngIf="!isExpanded"
      mat-icon-button
      class="shrinked-button"
      (click)="onClickExpand()"
    >
      <mat-icon>expand_more</mat-icon>
    </button>
  </div>

  <button
    *ngIf="isExpanded"
    mat-icon-button
    class="expanded-button"
    (click)="onClickShrink()"
  >
    <mat-icon>expand_less</mat-icon>
  </button>
</div>
