<div *ngIf="!initialLoadingService.hasInitialLoadingFinished" class="loading">
  <div class="flex-column">
    <div>Global Asset Tracker (GAT)</div>
    <mat-progress-bar
      mode="determinate"
      [value]="initialLoadingService.initialLoadingProgress"
    >
    </mat-progress-bar>
  </div>
</div>

<div
  *ngIf="
    initialLoadingService.hasInitialLoadingFinished &&
    initialLoadingService.hasInitialLoadingFinishedWithErrors
  "
  class="loading-error"
>
  <div class="grid-element-one flex-column align-items-center">
    <div flex-column>
      <div class="grid-element-one">OFFLINE</div>
      <mat-progress-bar mode="determinate" [value]="0"></mat-progress-bar>
    </div>

    <div style="font-size: 14px; margin-top: 24px">
      We are sorry, but the service is currently <b>unavailable</b>.
    </div>
    <div style="font-size: 36px">Please try again later.</div>
    <a
      target="_blank"
      href="mailto:stefan.havertz@accenture.com"
      class="report-problem-email"
      >Report this problem</a
    >
  </div>
  <div class="grid-element-two flex-column align-items-center">
    <i class="grid-element-two material-icons-outlined">do_disturb_alt</i>
  </div>
</div>

<ng-container
  *ngIf="
    initialLoadingService.hasInitialLoadingFinished &&
    !initialLoadingService.hasInitialLoadingFinishedWithErrors
  "
>
  <app-header></app-header>

  <div class="container">
    <router-outlet></router-outlet>
  </div>
</ng-container>
