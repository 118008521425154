<ngx-scanner-qrcode
  #scanner="scanner"
  (event)="onEventCode($event)"
></ngx-scanner-qrcode>

<div class="control-overlay" [class.readable-background]="!cameraIsOn">
  <div *ngIf="activity" class="activity">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>

  <!-- AREA 1: TOP -->
  <div class="row-1 form-field-density-2">
    <button
      mat-icon-button
      color="accent"
      class="mr-tiny"
      (click)="toggleCamera()"
    >
      <span class="material-symbols-outlined">
        {{ cameraIsOn ? "pause" : "play_arrow" }}
      </span>
    </button>
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      color="accent"
      class="width-100 camera-selection"
    >
      <mat-select [(ngModel)]="selectedDevice" class="color-accent-400">
        <mat-option *ngFor="let device of devices" [value]="device">
          {{ device.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- AREA 2: MIDDLE -->
  <div class="row-2">
    <div class="top-area-content">
      <div
        *ngFor="
          let successfulInventoryScan of successfulInventoryScans | keyvalue
        "
        class="flex-row align-items-center gap-small"
      >
        <span>{{ successfulInventoryScan.key }}</span>
        <span>-</span>
        <div class="flex-row gap-normal">
          <span *ngFor="let inventory of successfulInventoryScan.value">
            {{ inventory.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="top-area-overlay">
      <span>{{ successfulInventoryScans.size }}</span>
    </div>

    <div class="bottom-area-content" *ngIf="failedInventoryScans.size > 0">
      <span *ngFor="let failedInventoryScan of failedInventoryScans">
        {{ failedInventoryScan }}
      </span>
    </div>

    <div class="bottom-area-overlay" *ngIf="failedInventoryScans.size > 0">
      <span>{{ failedInventoryScans.size }}</span>
    </div>
  </div>

  <!-- AREA 3: BOTTOM ACTION BUTTONS -->
  <div class="row-3">
    <div class="keyboard">
      <button mat-icon-button color="accent" (click)="onClickKeyboard()">
        <mat-icon fontSet="material-symbols-outlined">keyboard</mat-icon>
      </button>
    </div>
  </div>

  <!-- AREA 4: BOTTOM -->
  <div class="row-4 form-field-density-2">
    <button mat-stroked-button (click)="onClickClose()" [disabled]="activity">
      CANCEL
    </button>
  </div>
</div>
