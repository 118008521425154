<h2 mat-dialog-title>{{ data.assetId }}</h2>

<mat-dialog-content>
  <h3 class="mat-h3">{{ data.assetName }}</h3>

  <div class="flex-column">
    <div class="flex-row align-items-center item">
      <mat-icon>lock</mat-icon>
      <span class="ml-small flex-row align-items-center">{{ data.confidentiality }}</span>
    </div>

    <div class="flex-row align-items-center item">
      <mat-icon>copyright</mat-icon>
      <span class="ml-small flex-row align-items-center">{{ data.owner }}</span>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="primary">OK</button>
</mat-dialog-actions>
