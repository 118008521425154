import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent {
  constructor(private _location: Location) {}

  onScannerClose() {
    this._location.back();
  }
}
