import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  PhoneToastDialogComponent,
  PhoneToastDialogData,
  PhoneToastDialogType,
} from '../component-dialogs/phone-toast-dialog/phone-toast-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhoneToastService {
  constructor(private _matDialog: MatDialog) {}

  show(type: PhoneToastDialogType, text: string) {
    const data: PhoneToastDialogData = {
      type,
      text,
    };

    this._matDialog.open(PhoneToastDialogComponent, {
      data,
      autoFocus: false,
      maxWidth: 'min(600px, 95vw)',
    });
  }
}
