<mat-dialog-content>
  <div class="flex-column form-field-density-2">
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <mat-label>Notes</mat-label>
      <textarea matInput [rows]="4" [(ngModel)]="notes"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>CANCEL</button>
  <button mat-flat-button color="primary" (click)="onClickOk()">OK</button>
</mat-dialog-actions>
