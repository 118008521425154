<h2 mat-dialog-title style="line-height: 24px">
  <span>Defect History:</span>
  <span class="color-primary-400">
    {{ data.assetId }}
  </span>
</h2>

<mat-dialog-content>
  <lib-spinner *ngIf="loading; else finishedLoading"></lib-spinner>

  <ng-template #finishedLoading>
    <div class="flex-column gap-small">
      <span *ngIf="assetDefects.length === 0; else hasDefects">
        There are no reported defects for that asset.
      </span>

      <ng-template #hasDefects>
        <ng-container *ngFor="let defect of assetDefects; let i = index">
          <div class="box">
            <!-- ROW 1 -->
            <div class="flex-row">
              <div class="flex-column">
                <ng-container *ngIf="defect.defectStateId === 1">
                  <span class="font-weight-500 color-black">OK</span>
                  <span class="small-text">fully functional</span>
                </ng-container>

                <ng-container *ngIf="defect.defectStateId === 2">
                  <span class="font-weight-500 color-black">DEFECT</span>
                  <span class="small-text"> partly functional</span>
                </ng-container>

                <ng-container *ngIf="defect.defectStateId === 3">
                  <span class="font-weight-500 color-black">DEFECT 100</span>
                  <span class="small-text">nonfunctional</span>
                </ng-container>
              </div>

              <div class="flex-grow-1"></div>

              <span
                *ngIf="defect.defectTypeId === 1 && i === 0"
                class="color-warn"
              >
                UNVERIFIED
              </span>
            </div>

            <!-- ROW 2 and following ... -->
            <div class="mt-normal mat-body info-grid">
              <span class="material-symbols-outlined fill font-size-16">
                brand_awareness
              </span>
              <span *ngIf="defect.defectTypeId === 1">reported</span>
              <span *ngIf="defect.defectTypeId === 2" class="color-green">
                verified
              </span>
            </div>

            <div class="mat-body info-grid">
              <span class="material-symbols-outlined fill font-size-16">
                person
              </span>
              <lib-user variant="phone" [oId]="defect.userOid"></lib-user>
            </div>

            <div class="mat-body info-grid">
              <span class="material-symbols-outlined fill font-size-16">
                schedule
              </span>
              <span>
                {{
                  defect.timestamp
                    | date : localeService.datetimePipeString(locale)
                }}
              </span>
            </div>

            <span class="mt-normal mat-body">{{ defect.comment }}</span>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close color="primary">OK</button>
</mat-dialog-actions>
