import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OUser } from '../common/oUser';
import { firstValueFrom } from 'rxjs';
import { OUserWrapper } from '../classes/oUserWrapper';
import { TenantUserOIdsQueryRoot } from '../graphql/crud/user';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UserSelectService {
  readonly oUsers = new Map<string, OUserWrapper>();
  readonly errorOIds = new Set<string>();

  constructor(private _http: HttpClient, private _apollo: Apollo) {}

  getOUserWrapperByOId(oId: string): OUserWrapper {
    const cachedOUser = this.oUsers.get(oId);
    if (cachedOUser) {
      return cachedOUser;
    }

    // Check if the oId provided an error the last time it was fetched.
    if (this.errorOIds.has(oId)) {
      throw new Error(`The oId '${oId}' could not be fetched before and is now skipped.`);
    }

    // Create Wrapper.
    const wrapper = new OUserWrapper();
    wrapper.isLoading = true;
    this.oUsers.set(oId, wrapper);

    // This oId was not loaded before. Query the data from
    // Microsoft Graph.

    const baseUrl = 'https://graph.microsoft.com/v1.0/users';

    firstValueFrom(this._http.get(`${baseUrl}/${oId}`))
      .then((result) => {
        wrapper.oUser = result as OUser;
        wrapper.isLoading = false;
      })
      .catch((error) => {
        this.errorOIds.add(oId);
        wrapper.isLoading = false;
        wrapper.isError = true;
      });

    return wrapper;
  }

  async getOUserByOId(oId: string): Promise<OUser | undefined> {
    const cachedOUser = this.oUsers.get(oId);
    if (cachedOUser?.oUser) {
      return cachedOUser.oUser;
    }

    // Check if the oId provided an error the last time it was fetched.
    if (this.errorOIds.has(oId)) {
      throw new Error(`The oId '${oId}' could not be fetched before and is now skipped.`);
    }

    // This oId was not loaded before. Query the data from
    // Microsoft Graph.

    const baseUrl = 'https://graph.microsoft.com/v1.0/users';

    try {
      const result = await firstValueFrom(this._http.get(`${baseUrl}/${oId}`));
      const fetchedOUser = result as OUser;

      if (!this.oUsers.has(oId)) {
        const wrapper = new OUserWrapper();
        wrapper.oUser = fetchedOUser;
        this.oUsers.set(oId, wrapper);
      }
      return fetchedOUser;
    } catch (error) {
      if (!this.oUsers.has(oId)) {
        const wrapper = new OUserWrapper();
        wrapper.isError = true;
        this.oUsers.set(oId, wrapper);
        this.errorOIds.add(oId);
      }
      return undefined;
    }
  }

  setOUserByOId(oUser: OUser, oId: string) {
    if (this.oUsers.has(oId)) {
      return;
    }

    const wrapper = new OUserWrapper();
    wrapper.oUser = oUser;

    this.oUsers.set(oId, wrapper);
  }

  async getTenantUserOIds(): Promise<string[]> {
    const result = await firstValueFrom(
      this._apollo.query<TenantUserOIdsQueryRoot>({
        query: gql`
          query TenantUserOIds {
            tenantUserOIds
          }
        `,
        fetchPolicy: 'cache-first',
      })
    );

    return result.data.tenantUserOIds;
  }
}
