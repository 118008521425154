import { Component, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { LOCAL_STORAGE } from '../../common/localStorage';

@Component({
  selector: 'app-scanner-demo',
  templateUrl: './scanner-demo.component.html',
  styleUrls: ['./scanner-demo.component.scss'],
})
export class ScannerDemoComponent implements AfterViewInit {
  @Output() onClose = new EventEmitter();
  @Output() onEvent = new EventEmitter<string>();

  @ViewChild('action') scanner!: NgxScannerQrcodeComponent;

  devices: ScannerQRCodeDevice[] = [];
  scanResults = new Set<string>();

  get selectedDevice() {
    return this._selectedDevice;
  }
  set selectedDevice(value: ScannerQRCodeDevice | undefined) {
    this._selectedDevice = value;
    if (value) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_DEVICE_ID, value.deviceId);

      this.scanner.isBeep = false;
      this.scanner.playDevice(value.deviceId);
    }
  }

  private _selectedDevice: ScannerQRCodeDevice | undefined;

  ngAfterViewInit(): void {
    const savedSelectedDeviceId = localStorage.getItem(LOCAL_STORAGE.SELECTED_DEVICE_ID);

    this.scanner.start((devices: ScannerQRCodeDevice[] | undefined) => {
      if (typeof devices === 'undefined' || devices.length === 0) {
        return;
      }

      this.devices = devices;

      if (savedSelectedDeviceId) {
        this.selectedDevice = this.devices.find((x) => x.deviceId === savedSelectedDeviceId);
      } else {
        // First run. Chose the first device.
        this.selectedDevice = this.devices[0];
      }
    });
  }

  onClickClose() {
    this.scanner.stop();
    this.onClose.emit();
  }

  onEventCode(events: ScannerQRCodeResult[]) {
    events.forEach((x) => this.scanResults.add(x.value));
  }
}
