<ngx-scanner-qrcode
  #action="scanner"
  (event)="onEventCode($event)"
></ngx-scanner-qrcode>

<div class="control-overlay">
  <div class="row-1 form-field-density-2">
    <mat-form-field appearance="outline" color="accent" class="width-100">
      <mat-select [(ngModel)]="selectedDevice" class="color-accent-400">
        <mat-option *ngFor="let device of devices" [value]="device">{{
          device.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row-2">
    <span *ngFor="let scanResult of scanResults" class="scan-result">{{
      scanResult
    }}</span>
  </div>

  <div class="row-3">
    <button mat-stroked-button (click)="onClickClose()">CANCEL</button>
  </div>
</div>
