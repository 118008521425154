import { Component } from '@angular/core';
import { v4 } from 'uuid';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {
  uuid1 = v4();

  get text() {
    return this.#text;
  }
  set text(value) {
    this.#text = value;
    this.qrCodes = value?.split(',') ?? [];
  }

  qrCodes: string[] = ['350108840267337'];

  #text: string | undefined;
}
