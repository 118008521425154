import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MsalService,
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { MAT_LUXON_DATE_FORMATS, LuxonDateAdapter } from '@angular/material-luxon-adapter';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedModule, sleep } from 'projects/shared/src/public-api';
import { MtxDrawerModule } from '@ng-matero/extensions/drawer';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { MtxLuxonDatetimeModule } from '@ng-matero/extensions-luxon-adapter';

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './component-helpers/header/header.component';
import { environment } from '../environments/environment';
import {
  AuthenticationResult,
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { SelectionService } from './services/selection.service';
import { TestComponent } from './components/test/test.component';
import { MenuComponent } from './component-helpers/menu/menu.component';
import { SwitchTenantDialogComponent } from './component-dialogs/switch-tenant-dialog/switch-tenant-dialog.component';
import { ExpansionTileComponent } from './component-helpers/expansion-tile/expansion-tile.component';
import { ExpansionTileHeaderComponent } from './component-helpers/expansion-tile/expansion-tile-header/expansion-tile-header.component';
import { ExpansionTileContentComponent } from './component-helpers/expansion-tile/expansion-tile-content/expansion-tile-content.component';
import { ExpansionTileFooterComponent } from './component-helpers/expansion-tile/expansion-tile-footer/expansion-tile-footer.component';
import { PhoneToastDialogComponent } from './component-dialogs/phone-toast-dialog/phone-toast-dialog.component';
import { AssetsScanComponent } from './component-helpers/assets-scan/assets-scan.component';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';
import { LoginComponent } from './components/login/login.component';
import { ScannerComponent } from './components/scanner/scanner.component';
import { ScannerDemoComponent } from './component-helpers/scanner-demo/scanner-demo.component';
import { DateTime } from 'luxon';
import { ActionDetailsComponent } from './component-helpers/action-details/action-details.component';
import { NgScanComponent } from './component-helpers/ng-scan/ng-scan.component';
import { NgScanKeyboardDialogComponent } from './component-helpers/ng-scan/ng-scan-keyboard-dialog/ng-scan-keyboard-dialog.component';
import { NgScanFromOrToDialogComponent } from './component-helpers/ng-scan/ng-scan-from-or-to-dialog/ng-scan-from-or-to-dialog.component';
import { NgScanNotesDialogComponent } from './component-helpers/ng-scan/ng-scan-notes-dialog/ng-scan-notes-dialog.component';
import { firstValueFrom } from 'rxjs';
import { DefectComponent } from './components/defect/defect.component';
import { ShowDefectsDialogComponent } from './component-dialogs/show-defects-dialog/show-defects-dialog.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import {
  AssetBaseInfoDialogComponent
} from "./component-dialogs/asset-base-info-dialog/asset-base-info-dialog.component";
import { AssetsScanInfoComponent } from './components/assets-scan-info/assets-scan-info.component';

const adjustedBaseUrl =
  environment.apiBaseUrl[environment.apiBaseUrl.length - 1] === '/'
    ? environment.apiBaseUrl.slice(0, environment.apiBaseUrl.length - 2)
    : environment.apiBaseUrl;

const apiUrl = adjustedBaseUrl + '/api';
const graphqlUrl = adjustedBaseUrl + '/graphql';
let wsUrl = adjustedBaseUrl + '/subscriptions';
wsUrl = wsUrl.replace('https://', 'wss://');
wsUrl = wsUrl.replace('http://', 'ws://');

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
protectedResourceMap.set('https://graph.microsoft.com/v1.0/*', ['user.read.all']);
protectedResourceMap.set(`${apiUrl}/myUser/`, environment.apiScopes);
protectedResourceMap.set(`${apiUrl}/assets/*`, environment.apiScopes);
protectedResourceMap.set(graphqlUrl, environment.apiScopes);

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin + '/login',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,

    authRequest: {
      scopes: ['openid', 'profile', 'email', environment.apiScopes[0]],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HomeComponent,
    HeaderComponent,
    LoginFailedComponent,
    TestComponent,
    MenuComponent,
    SwitchTenantDialogComponent,
    ExpansionTileComponent,
    ExpansionTileHeaderComponent,
    ExpansionTileContentComponent,
    ExpansionTileFooterComponent,
    PhoneToastDialogComponent,
    AssetsScanComponent,
    TermsOfUseComponent,
    PrivacyStatementComponent,
    LoginComponent,
    ScannerComponent,
    ScannerDemoComponent,
    ActionDetailsComponent,
    NgScanComponent,
    NgScanKeyboardDialogComponent,
    NgScanFromOrToDialogComponent,
    NgScanNotesDialogComponent,
    DefectComponent,
    ShowDefectsDialogComponent,
    InventoryComponent,
    AssetBaseInfoDialogComponent,
    AssetsScanInfoComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ApolloModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,

    MatLuxonDateModule,
    MtxLuxonDatetimeModule,
    MtxDatetimepickerModule,
    NgxScannerQrcodeModule,
    QRCodeModule,
    MtxDrawerModule,

    MatBottomSheetModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatTabsModule,
    MatToolbarModule,
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (
        httpLink: HttpLink,
        selectionService: SelectionService,
        msalService: MsalService
      ) => {
        const auth = setContext((operation, context) => {
          const tenantId = selectionService.selectedTenant?.id;

          if (!tenantId) {
            return {};
          } else {
            return {
              headers: {
                'Gat-Tenant-Id': tenantId,
                'Gat-Date': new Date().toISOString(),
                'Gat-Zone': DateTime.local().zone.name,
              },
            };
          }
        });

        const http = ApolloLink.from([
          auth,
          httpLink.create({
            uri: graphqlUrl,
          }),
        ]);

        const ws = new GraphQLWsLink(
          createClient({
            url: wsUrl,
            connectionParams: async () => {
              const tenantId = selectionService.selectedTenant?.id;
              const account = msalService.instance.getAllAccounts()[0];

              let attempts = 0;
              const maxAttempts = 10;
              let result: AuthenticationResult | undefined;
              do {
                result = await firstValueFrom(
                  msalService.acquireTokenSilent({
                    scopes: ['openid', 'profile', 'email', environment.apiScopes[0]],
                    account: account,
                  })
                );
                // Check, if the token already has expired.
                // This can happen, if the web site it visited after some time (old token has expired)
                // and the regular GraphQL queries have to yet be processes to that a valid new token is available.

                if (result.expiresOn && result.expiresOn.getTime() < Date.now()) {
                  console.log('(Cached) Token has expired. Waiting for next attempt.');
                  attempts++;
                  await sleep(2000);
                } else {
                  break;
                }
              } while (attempts < maxAttempts);

              const authorization = `Bearer ${result.accessToken}`;
              return {
                authorization,
                gatTenantId: tenantId,
              };
            },
            shouldRetry: () => {
              return true;
            },
          })
        );

        const link = split(
          // split based on operation type
          ({ query }) => {
            const call = getMainDefinition(query);
            return call.kind === 'OperationDefinition' && call.operation === 'subscription';
          },
          ws,
          http
        );

        return {
          cache: new InMemoryCache({
            typePolicies: {
              OptionOutput: {
                fields: {
                  optionItems: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                },
              },
              PlanOutput: {
                fields: {
                  planAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                  planSteps: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                },
              },
              PlanStepOutput: {
                fields: {
                  planStepAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                },
              },
              Query: {
                fields: {
                  planAssets: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                  planStepActions: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                  getMyPlanningActions: {
                    merge(existing, incoming, { mergeObjects }) {
                      return incoming;
                    },
                  },
                },
              },
            },
          }),
          link,
        };
      },
      deps: [HttpLink, SelectionService, MsalService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false, logErrors: true }),
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
