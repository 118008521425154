import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Operator } from 'projects/shared/src/lib/classes/operator';
import { UserOutput } from 'projects/shared/src/lib/graphql/output/userOutput';
import { UserConfigService } from 'projects/shared/src/lib/services/user-config.service';
import { Subject, firstValueFrom } from 'rxjs';
import { SelectionService } from './selection.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InitialLoadingService {
  get initialLoadingProgress() {
    return this._initialLoadingProgress;
  }
  get hasInitialLoadingFinished() {
    return this._hasInitialLoadingFinished;
  }
  get hasInitialLoadingFinishedChanged() {
    return this._hasInitialLoadingFinishedChanged;
  }
  get hasInitialLoadingFinishedWithErrors() {
    return this._hasInitialLoadingFinishedWithErrors;
  }

  private _operator = new Operator();
  private _initialLoadingProgress = 0;
  private _hasInitialLoadingFinished: boolean | undefined;
  private _hasInitialLoadingFinishedWithErrors: boolean | undefined;
  private _myUser: UserOutput | undefined;
  private _hasInitialLoadingFinishedChanged = new Subject<boolean>();

  constructor(
    private _http: HttpClient,
    private _userConfigService: UserConfigService,
    private _selectionService: SelectionService
  ) {}

  async loadInitialDataAsync() {
    this._operator.addPromiseTask(this._loadMyUserAsync.bind(this));
    this._operator.addPromiseTask(this._setSelectionsAsync.bind(this));

    this._operator.progress.subscribe({
      next: async (progress) => {
        this._initialLoadingProgress = progress;

        if (this._operator.isFinished) {
          if (!this._operator.withErrors) {
            // Make sure that the last progress update to 100% is shown before
            // removing the loading panel.
            await new Promise((x) => setTimeout(x, 1000));
            this._hasInitialLoadingFinished = true;
            this._hasInitialLoadingFinishedWithErrors = false;
            this._hasInitialLoadingFinishedChanged.next(true);
          } else {
            this._hasInitialLoadingFinished = true;
            this._hasInitialLoadingFinishedWithErrors = true;
            this._hasInitialLoadingFinishedChanged.next(false);
          }
        }
      },
    });

    await this._operator.executeTasksAsync();
  }

  private async _loadMyUserAsync() {
    const url = environment.apiBaseUrl + '/api/myUser';
    this._myUser = (await firstValueFrom(this._http.get(url))) as UserOutput;
    this._selectionService.updateMyUser(this._myUser);
    if (this._myUser?.userConfig) {
      this._userConfigService.init(this._myUser.userConfig);
    }
  }

  private async _setSelectionsAsync() {
    if (
      typeof this._myUser === 'undefined' ||
      typeof this._myUser.userRoles === 'undefined' ||
      this._myUser.userRoles == null ||
      !Array.isArray(this._myUser.userRoles) ||
      this._myUser.userRoles.length === 0
    ) {
      return;
    }

    const userRoles = this._myUser.userRoles;

    try {
      // 1. Make sure that a user config is available.
      let userConfig = this._userConfigService.userConfig;

      if (!userConfig) {
        // This will not happen since the backend ALWAYS returns an userConfig
        // object (even "empty").
        throw new Error('The backend provided an empty userConfig object.');
      } else {
        // 2. Check config against previously loaded data.
        // Does the user config reference data that is "not there anymore"
        // (e.g. due to access rights changes)?
        // ATTENTION: Set the tenantId first as it is required to be included as header
        // in every graphql communication.
        if (
          !userConfig.mTenantId ||
          !userRoles.map((x) => x.tenantId ?? 'na').includes(userConfig.mTenantId)
        ) {
          this._selectionService.selectedTenant = userRoles[0].tenant;
          await this._userConfigService.updateAsync({
            mTenantId: userRoles[0].tenantId,
          });
        } else {
          this._selectionService.selectedTenant = userRoles.find(
            (x) => x.tenantId === userConfig?.mTenantId
          )?.tenant;
        }
      }
    } catch (error) {
      console.log(error);
      // Do nothing else here.
    }
  }
}
