import { Apollo, gql } from 'apollo-angular';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  GetScannedAssetsQueryArgs,
  GetScannedAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAssetPlanning';
import { ScannedAssetOutput } from 'projects/shared/src/lib/graphql/output/scannedAssetOutput';
import { firstValueFrom } from 'rxjs';

export class ScannedAssetsWrapper {
  get scannedAssets(): ScannedAssetOutput[] {
    if (this.state === 0) {
      setTimeout(() => {
        this._loadData(this._planStepActionId);
      }, 100);
      return [];
    }

    return this._scannedAssets;
  }
  set scannedAssets(value) {
    this._scannedAssets = value;
  }

  get state(): number {
    if (this._state === 0) {
      setTimeout(() => {
        this._loadData(this._planStepActionId);
      }, 100);
    }
    return this._state;
  }
  set state(value) {
    this._state = value;
  }

  errorMessage: string | undefined;

  get booked(): number {
    return this.scannedAssets?.filter((x) => x.assetBooked === true).length ?? 0;
  }

  private _scannedAssets: ScannedAssetOutput[] = [];
  private _state: number = 0; // 1 = loading  2 = finished (ok)  3 = finished (failure)

  constructor(private _apollo: Apollo, private _planStepActionId: string) {}

  private async _loadData(planStepActionId: string) {
    if (this._state !== 0) {
      return;
    }
    this.state = 1;

    const variables: GetScannedAssetsQueryArgs = {
      planStepActionId,
    };

    try {
      const result = await firstValueFrom(
        this._apollo.query<GetScannedAssetsQueryRoot>({
          query: gql`
            query GetScannedAssets($planStepActionId: String!) {
              getScannedAssets(planStepActionId: $planStepActionId) {
                planStepActionId
                assetId
                assetBooked
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.scannedAssets = result.data.getScannedAssets;
      this.state = 2;
    } catch (error) {
      this.state = 3;
      const message = new CatchError(error).message;
      this.errorMessage = message;
      console.log(error);
    }
  }
}
