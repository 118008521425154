import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { v4 } from 'uuid';

export type NgScanKeyboardDialogResult = string | undefined;

@Component({
  selector: 'app-ng-scan-keyboard-dialog',
  templateUrl: './ng-scan-keyboard-dialog.component.html',
  styleUrls: ['./ng-scan-keyboard-dialog.component.scss'],
})
export class NgScanKeyboardDialogComponent {
  assetId: string | undefined;
  uuid1 = v4();

  constructor(private _dialogRef: MatDialogRef<NgScanKeyboardDialogComponent>) {}

  onClickApply() {
    if (!this.assetId) {
      return;
    }

    this._dialogRef.close(this.assetId);
  }
}
