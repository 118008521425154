import { Component } from '@angular/core';

@Component({
  selector: 'app-expansion-tile-footer',
  templateUrl: './expansion-tile-footer.component.html',
  styleUrls: ['./expansion-tile-footer.component.scss']
})
export class ExpansionTileFooterComponent {

}
