<h2 mat-dialog-title>Switch Tenant</h2>

<mat-dialog-content>
  <div class="flex-column mat-body">
    <span> You are currently logged into the tenant: </span>

    <span class="mt-small selected-tenant">
      {{ selectionService.selectedTenant?.name }}
    </span>

    <span class="mt-small mb-normal">
      You have access to the following other tenants (click on one to switch
      tenants).
    </span>

    <mat-divider></mat-divider>
    <ng-container *ngFor="let tenant of selectionService.otherAvailableTenants">
      <a matRipple (click)="onClickTenant(tenant)">
        <mat-icon>business</mat-icon>
        <span>{{ tenant.name }}</span>
      </a>
      <mat-divider></mat-divider>
    </ng-container>
  </div>
</mat-dialog-content>
