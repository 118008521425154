import {
  Component,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  OnInit,
  AfterViewInit,
  Inject,
} from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { LOCAL_STORAGE } from '../../common/localStorage';
import { PlanStepActionOutput } from 'projects/shared/src/lib/graphql/output/planStepActionOutput';
import { ScannedAssetOutput } from 'projects/shared/src/lib/graphql/output/scannedAssetOutput';
import {
  CreateTenantActionsMutationArgs,
  CreateTenantActionsMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { TenantActionInputCreate } from 'projects/shared/src/lib/graphql/input/create/tenantActionInputCreate';
import { firstValueFrom } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { PhoneToastService } from '../../services/phone-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { PhoneToastDialogType } from '../../component-dialogs/phone-toast-dialog/phone-toast-dialog.component';
import { PlanStepActionHelper } from 'projects/shared/src/lib/classes/planStepActionHelper';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PlanStepOutput } from 'projects/shared/src/lib/graphql/output/planStepOutput';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { v4 } from 'uuid';
import {
  NgScanKeyboardDialogComponent,
  NgScanKeyboardDialogResult,
} from '../ng-scan/ng-scan-keyboard-dialog/ng-scan-keyboard-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export type AssetsScanData = {
  plan: PlanOutput;
  planStep: PlanStepOutput;
  planStepAction: PlanStepActionOutput;
  assetsInfo: ScannedAssetOutput[];
  startAssetIds: string[];
};

type BookedAndScanned = {
  booked: boolean;
  scanned: boolean;
};

@Component({
  selector: 'app-assets-scan',
  templateUrl: './assets-scan.component.html',
  styleUrls: ['./assets-scan.component.scss'],
})
export class AssetsScanComponent implements OnInit, AfterViewInit {
  @Input() inputData: AssetsScanData | undefined;
  @Output() onClose = new EventEmitter();

  @ViewChild('action') scanner!: NgxScannerQrcodeComponent;

  devices: ScannerQRCodeDevice[] = [];

  // string = assetId
  relevantAssets = new Map<string, BookedAndScanned>();
  scannedAssetIds: string[] = [];

  get selectedDevice() {
    return this._selectedDevice;
  }
  set selectedDevice(value: ScannerQRCodeDevice | undefined) {
    this._selectedDevice = value;
    if (value) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_DEVICE_ID, value.deviceId);

      this.scanner.isBeep = false;
      this.scanner.vibrate = 0;
      this.scanner.playDevice(value.deviceId);
    }
  }

  activity = false;

  planStepActionHelper = PlanStepActionHelper;
  uuid = v4();
  manualKeyboardInput: string | undefined;
  debugMode = false;
  debugEvents: string[] = [];

  private _selectedDevice: ScannerQRCodeDevice | undefined;

  constructor(
    private _apollo: Apollo,
    private _toastService: PhoneToastService,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private _matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    for (let x of this.inputData?.assetsInfo.sortBy((y) => y.assetId) ?? []) {
      const bookedAndScanned: BookedAndScanned = {
        booked: x.assetBooked,
        scanned: this.inputData?.startAssetIds.includes(x.assetId) ?? false,
      };

      this.relevantAssets.set(x.assetId, bookedAndScanned);
      if (bookedAndScanned.scanned) {
        this.scannedAssetIds.push(x.assetId);
      }
    }
  }

  ngAfterViewInit(): void {
    const savedSelectedDeviceId = localStorage.getItem(LOCAL_STORAGE.SELECTED_DEVICE_ID);

    this.scanner.start((devices: ScannerQRCodeDevice[] | undefined) => {
      if (typeof devices === 'undefined' || devices.length === 0) {
        return;
      }

      this.devices = devices;

      if (savedSelectedDeviceId) {
        this.selectedDevice = this.devices.find((x) => x.deviceId === savedSelectedDeviceId);
      } else {
        // First run. Chose the first device.
        this.selectedDevice = this.devices[0];
      }
    });
  }

  onClickClose() {
    this.scanner.stop();
    this.onClose.emit(false);
  }

  onClickKeyboard() {
    const dialog = this._matDialog.open(NgScanKeyboardDialogComponent);

    dialog.afterClosed().subscribe((result: NgScanKeyboardDialogResult) => {
      if (!result) {
        return;
      }

      (document.activeElement as HTMLElement)?.blur();

      this.#handleAssetId(result);
    });
  }

  #handleAssetId(id: string) {
    const bookedAndScanned = this.relevantAssets.get(id);
    if (
      !bookedAndScanned ||
      bookedAndScanned.booked === true ||
      bookedAndScanned.scanned === true
    ) {
      return;
    }

    bookedAndScanned.scanned = true;

    if (!this.scannedAssetIds.includes(id)) {
      this.scannedAssetIds.push(id);
    }
  }

  onEventQrCode(events: ScannerQRCodeResult[]) {
    this.debugEvents.unshift('onEventQrCode: ' + JSON.stringify(events));
    for (const id of events.map((x) => x.value)) {
      this.#handleAssetId(id);
    }
  }

  async onClickBook() {
    if (!this.inputData) {
      return;
    }

    this.activity = true;
    try {
      const data: TenantActionInputCreate = {
        actionTypeId: this.inputData.planStepAction.actionTypeId,
        planStepActionId: this.inputData.planStepAction.id,
        planId: this.inputData.plan.id,
        description: this.inputData.planStepAction.description,
        fromUserOid: this.inputData.planStepAction.fromUserOid,
        fromLocationId: this.inputData.planStepAction.fromLocationId,
        fromMail: this.inputData.planStepAction.fromMail,
        fromOther: this.inputData.planStepAction.fromOther,
        toUserOid: this.inputData.planStepAction.toUserOid,
        toLocationId: this.inputData.planStepAction.toLocationId,
        toMail: this.inputData.planStepAction.toMail,
        toOther: this.inputData.planStepAction.toOther,
        transitLocationId: this.inputData.planStepAction.transitLocationId,
      };

      const variables: CreateTenantActionsMutationArgs = {
        data,
        assetIds: this.scannedAssetIds,
      };

      const result = await firstValueFrom(
        this._apollo.mutate<CreateTenantActionsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            mutation CreateTenantActions($assetIds: [String!]!, $data: TenantActionInputCreate!) {
              createTenantActions(assetIds: $assetIds, data: $data) {
                ...FullFragmentTenantAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      const actionName = PlanStepActionHelper.getActionName(
        this.inputData.planStepAction,
        'uppercase'
      );

      this.scanner.stop();
      this._toastService.show(
        PhoneToastDialogType.Success,
        `You successfully booked the planned action <b>${actionName}</b> for the ${
          this.scannedAssetIds.length
        } scanned ${this.scannedAssetIds.length === 1 ? 'asset' : 'assets'}.`
      );
      this.onClose.emit(true);
    } catch (error) {
      const message = new CatchError(error).message;
      this._toastService.show(PhoneToastDialogType.Error, message);
    } finally {
      this.activity = false;
    }
  }
}
