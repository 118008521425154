import { Component } from '@angular/core';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private _drawer: MtxDrawer) {}

  onClickMenu() {
    const drawer = this._drawer.open(MenuComponent, {
      position: 'right',
      height: '100%',
      hasBackdrop: true,
    });

    drawer.afterDismissed().subscribe(() => {
      (document.activeElement as HTMLElement)?.blur();
    });
  }
}
