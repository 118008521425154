import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetsScanData } from '../../component-helpers/assets-scan/assets-scan.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import {
  GetMyPlanningActionsQueryArgs,
  GetMyPlanningActionsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAssetPlanning';
import { FULL_FRAGMENT_PLANNING_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanningAction';
import { PlanningActionOutput } from 'projects/shared/src/lib/graphql/output/planningActionOutput';
import { PhoneToastService } from '../../services/phone-toast.service';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { PhoneToastDialogType } from '../../component-dialogs/phone-toast-dialog/phone-toast-dialog.component';
import { FULL_FRAGMENT_PLAN } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlan';
import { FULL_FRAGMENT_PLAN_STEP } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStep';
import { FULL_FRAGMENT_PLAN_STEP_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentPlanStepAction';
import { DateTime } from 'luxon';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ScannedAssetsWrapper } from '../../common/scannedAssetsWrapper';
import { environment } from 'projects/phone/src/environments/environment';
import { SelectionService } from '../../services/selection.service';
import { HttpClient } from '@angular/common/http';
import {
  TenantActionsLatestQueryArgs,
  TenantActionsLatestQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { MsalService } from '@azure/msal-angular';
import { PlanStepActionUserSubscriptionRoot } from 'projects/shared/src/lib/graphql/crud/planStepAction';
import { MyPlansQueryArgs, MyPlansQueryRoot } from 'projects/shared/src/lib/graphql/crud/plan';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import {
  ShowDefectsDialogComponent,
  ShowDefectsDialogData,
} from '../../component-dialogs/show-defects-dialog/show-defects-dialog.component';
import {
  AssetColumnService, AssetPropertyInfo,
} from "../../../../../shared/src/lib/services/asset-column.service";
import {
  AssetBaseInfoDialogComponent
} from "../../component-dialogs/asset-base-info-dialog/asset-base-info-dialog.component";

type MyAsset = {
  id: string;
  actionPreviouslyLocatedAt: string;
  actionCurrentlyLocatedAt: string;
  //actionCurrentLocationInfo: string;
  actionLatestBookingNotes: string | null;
  //actionCurrentResponsible: string;
  actionLatestBookingBy: string;
  currentPlanName: string | null;
  defectType: number | null;
  defectState: number | null;
  defectUser: string | null;
  defectComment: string | null;
  defectTime: string | null;
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  qrCodeButtonsVisible = false;
  scanAsset = false;
  scanAssets = false;
  ngScan = false;
  nextAssetsScanData: AssetsScanData | undefined;
  myPlannedActionsLoading = false;
  myPlanningActions: PlanningActionOutput[] = [];
  sinceDay: any = DateTime.now().startOf('day').minus({ days: 5 }).toJSDate();
  untilDay: any = DateTime.now().startOf('day').plus({ days: 14 }).toJSDate();

  myPlans: PlanOutput[] | undefined | null;
  myPlansLoading = false;
  myPlansCheckStartDate: any = DateTime.now().startOf('day').minus({ days: 5 }).toJSDate();
  myPlansCheckEndDate: any = DateTime.now().startOf('day').plus({ days: 14 }).toJSDate();

  readonly planStepActionScanData = new Map<string, ScannedAssetsWrapper>();

  myAssets: MyAsset[] = [];
  myAssetsTenantActions: TenantActionOutput[] = [];
  inMyCareAssets = new Map<
    string,
    {
      myAsset?: MyAsset;
      tenantAction: TenantActionOutput;
    }
  >();
  inMyResponsibilityAssets = new Map<
    string,
    {
      myAsset?: MyAsset;
      tenantAction: TenantActionOutput;
    }
  >();
  myAssetsLoading = false;
  myOId: string | undefined;

  #planStepActionUpdateUserSubscription: Subscription | undefined;
  properties: Map<string, AssetPropertyInfo> = new Map<string, AssetPropertyInfo>();

  constructor(
    private apollo: Apollo,
    private toastService: PhoneToastService,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private selectionService: SelectionService,
    private http: HttpClient,
    private msalService: MsalService,
    private matDialog: MatDialog,
    private assetColumnService: AssetColumnService
  ) {}

  async ngOnInit() {
    const account = this.msalService.instance.getAllAccounts()[0];
    this.myOId = account.idTokenClaims?.oid;

    const tenantId = this.selectionService.selectedTenant?.id ?? 'na';

    this.properties = await this.assetColumnService.getColumns(tenantId)

    this.#loadMyPlansData();
    this.#loadMyPlannedAssetsData();
    this.#loadMyAssetsData();
    this.#startSubscriptions();
  }

  ngOnDestroy(): void {
    this.#planStepActionUpdateUserSubscription?.unsubscribe();
  }

  onDatePickerClosed() {
    this.#loadMyPlannedAssetsData();
  }

  onMyPlansDatePickerClosed() {
    // Adjust end date.
    this.myPlansCheckEndDate = this.myPlansCheckEndDate.endOf('day');
    this.#loadMyPlansData();
  }

  onClickPlusOne() {
    //this.daysIntoTheFuture++;
    this.#loadMyPlannedAssetsData();
  }

  async onClickScan(plannedAction: PlanningActionOutput) {
    if (!plannedAction.planStepAction || !plannedAction.planStep || !plannedAction.plan) {
      return;
    }

    const assetsInfo =
      this.planStepActionScanData.get(plannedAction.planStepActionId)?.scannedAssets ?? [];

    const startAssetIds: string[] = [];

    this.nextAssetsScanData = {
      plan: plannedAction.plan,
      planStep: plannedAction.planStep,
      planStepAction: plannedAction.planStepAction,
      assetsInfo,
      startAssetIds,
    };
    this.scanAssets = true;
  }

  onCloseNgScan(event: [planStepActionId: string | undefined, booked: boolean]) {
    this.ngScan = false;
    if (!event[1]) {
      return;
    }

    this.#loadMyPlannedAssetsData().then(() => {
      if (event[0]) {
        const wrapper = this.planStepActionScanData.get(event[0]!);
        if (wrapper) {
          wrapper.state = 0; // Trigger a reload of the scanned assets.
        }
      }
    });
    this.#loadMyAssetsData();
  }

  onClosePlanStepActionScan(planStepActionId: string | undefined, booked: boolean) {
    this.scanAssets = false;
    if (!booked || !planStepActionId) {
      return;
    }

    this.#loadMyPlannedAssetsData().then(() => {
      const wrapper = this.planStepActionScanData.get(planStepActionId);
      if (!wrapper) {
        return;
      }

      wrapper.state = 0; // Trigger a reload of the scanned assets.
    });
    this.#loadMyAssetsData();
  }

  showAssetDefectHistory(assetId: string, $event: MouseEvent) {
    $event.stopPropagation();
    const data: ShowDefectsDialogData = {
      assetId,
    };

    const dialog = this.matDialog.open(ShowDefectsDialogComponent, {
      data,
      width: '90%',
      maxWidth: 704,
    });

    dialog.afterClosed().subscribe(() => {
      (document.activeElement as HTMLElement)?.blur();
    });
  }

  // #region Private Methods

  async #loadMyPlannedAssetsData() {
    this.myPlannedActionsLoading = true;

    try {
      // The "sinceDay" and "untilDay" objects are either a JS Date (if they were
      // not changed by the user) or a luxon DateTime object. We have to handle both.

      const sinceDayTest = DateTime.fromJSDate(this.sinceDay);
      const sinceDayDateTime = sinceDayTest.isValid ? sinceDayTest : (this.sinceDay as DateTime);

      const untilDayTest = DateTime.fromJSDate(this.untilDay);
      const untilDayDateTime = untilDayTest.isValid ? untilDayTest : (this.untilDay as DateTime);

      const variables: GetMyPlanningActionsQueryArgs = {
        sinceDay: sinceDayDateTime.toJSDate(),
        untilDay: untilDayDateTime.endOf('day').toJSDate(),
      };

      const result = await firstValueFrom(
        this.apollo.query<GetMyPlanningActionsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PLANNING_ACTION}
            ${FULL_FRAGMENT_PLAN}
            ${FULL_FRAGMENT_PLAN_STEP}
            ${FULL_FRAGMENT_PLAN_STEP_ACTION}
            query GetMyPlanningActions($sinceDay: DateTime!, $untilDay: DateTime!) {
              getMyPlanningActions(sinceDay: $sinceDay, untilDay: $untilDay) {
                ...FullFragmentPlanningAction
                plan {
                  ...FullFragmentPlan
                }
                planStep {
                  ...FullFragmentPlanStep
                }
                planStepAction {
                  ...FullFragmentPlanStepAction
                  actionType {
                    name
                  }
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.myPlanningActions = result.data.getMyPlanningActions;
      for (let planStepActionId of this.myPlanningActions.map((x) => x.planStepActionId)) {
        if (!this.planStepActionScanData.has(planStepActionId)) {
          this.planStepActionScanData.set(
            planStepActionId,
            new ScannedAssetsWrapper(this.apollo, planStepActionId)
          );
        }
      }
    } catch (error) {
      const message = new CatchError(error).message;
      console.log(error);
      this.toastService.show(PhoneToastDialogType.Error, message);
    } finally {
      this.myPlannedActionsLoading = false;
    }
  }

  async #loadMyPlansData() {
    this.myPlansLoading = true;
    try {
      const sinceDayTest = DateTime.fromJSDate(this.myPlansCheckStartDate);
      const sinceDayDateTime = sinceDayTest.isValid
        ? sinceDayTest
        : (this.myPlansCheckStartDate as DateTime);

      const untilDayTest = DateTime.fromJSDate(this.myPlansCheckEndDate);
      const untilDayDateTime = untilDayTest.isValid
        ? untilDayTest
        : (this.myPlansCheckEndDate as DateTime);

      const variables: MyPlansQueryArgs = {
        sinceDay: sinceDayDateTime.toJSDate(),
        untilDay: untilDayDateTime.endOf('day').toJSDate(),
      };

      const result = await firstValueFrom(
        this.apollo.query<MyPlansQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_PLAN}
            query MyPlans($sinceDay: DateTime!, $untilDay: DateTime!) {
              myPlans(sinceDay: $sinceDay, untilDay: $untilDay) {
                ...FullFragmentPlan
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.myPlans = result.data.myPlans?.sortBy((x) => x.planStart, 'desc');
    } catch (error) {
    } finally {
      this.myPlansLoading = false;
    }
  }

  async #loadMyAssetsData() {
    this.myAssetsLoading = true;

    try {
      const url =
        environment.apiBaseUrl + '/api/assets/myAssets/' + this.selectionService.selectedTenant?.id;

      const result = await firstValueFrom(this.http.post<MyAsset[]>(url, null));
      this.myAssets = result;

      if (this.myAssets.length === 0) {
        return;
      }

      // Now load the latest tenant action for each asset to have all data available.
      const variables: TenantActionsLatestQueryArgs = {
        assetIds: this.myAssets.map((x) => x.id),
      };

      const apolloResult = await firstValueFrom(
        this.apollo.query<TenantActionsLatestQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            query TenantActionsLatest($assetIds: [String!]!) {
              tenantActionsLatest(assetIds: $assetIds) {
                ...FullFragmentTenantAction
                actionType {
                  id
                  name
                }
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.myAssetsTenantActions = apolloResult.data.tenantActionsLatest;

      this.inMyCareAssets.clear();
      this.inMyResponsibilityAssets.clear();

      for (const action of this.myAssetsTenantActions.sortBy((x) => x.timestamp)) {
        if (action.toUserOid === this.myOId) {
          // "TO me"
          this.inMyCareAssets.set(action.assetId, {
            myAsset: this.myAssets.find((x) => x.id === action.assetId),
            tenantAction: action,
          });
        } else {
          // "BY me" and NOT "TO me"
          this.inMyResponsibilityAssets.set(action.assetId, {
            myAsset: this.myAssets.find((x) => x.id === action.assetId),
            tenantAction: action,
          });
        }
      }

      console.log(this.myAssetsTenantActions);
    } catch (error) {
      // TODO
    } finally {
      this.myAssetsLoading = false;
    }
  }

  #startSubscriptions() {
    this.#planStepActionUpdateUserSubscription = this.apollo
      .subscribe<PlanStepActionUserSubscriptionRoot>({
        query: gql`
          subscription PlanStepActionUserSubscription {
            planStepActionUserSubscription {
              data {
                planStepActionId
                date
              }
            }
          }
        `,
      })
      .subscribe((result) => {
        console.log(result);
        this.#loadMyPlannedAssetsData();
      });
  }

  // #endregion Private Methods
  onAssetClicked(myAsset: any) {
    const owner = myAsset[this.properties.get('Owner')!.id];
    const confidentiality = myAsset[this.properties.get('Confidentiality')!.id];
    const assetId = myAsset.id;
    const assetName = myAsset[this.properties.get('Name')!.id];

    this.matDialog.open(AssetBaseInfoDialogComponent, {
      data: {owner, confidentiality, assetId, assetName},
      width: '90%',
      maxWidth: 704,
    });
  }
}
