<ngx-scanner-qrcode
  #scanner="scanner"
  (event)="onEventCode($event)"
></ngx-scanner-qrcode>

<div class="control-overlay" [class.readable-background]="!cameraIsOn">
  <div *ngIf="activity" class="activity">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>

  <!-- AREA 1: TOP -->
  <div class="row-1 form-field-density-2">
    <button
      mat-icon-button
      color="accent"
      class="mr-tiny"
      (click)="toggleCamera()"
    >
      <span class="material-symbols-outlined">
        {{ cameraIsOn ? "pause" : "play_arrow" }}
      </span>
    </button>
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      color="accent"
      class="width-100"
    >
      <mat-select [(ngModel)]="selectedDevice" class="color-accent-400">
        <mat-option *ngFor="let device of devices" [value]="device">
          {{ device.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- AREA 2: MIDDLE -->
  <div class="row-2">
    <div *ngIf="data" class="flex-column align-items-center">
      <span [class.color-warn]="!data.asset">Asset</span>
      <span class="text" [class.color-warn]="!data.asset">
        {{ data.asset?.id ?? "unknown" }}
      </span>
      <ng-container *ngIf="data.asset">
        <span
          *ngIf="!data.asset.defectState || data.asset.defectState === 1"
          class="defect-text color-green"
        >
          OK
        </span>
        <span
          *ngIf="data.asset.defectState === 2"
          class="defect-text color-warn"
        >
          DEFECT
        </span>
        <span
          *ngIf="data.asset.defectState === 3"
          class="defect-text color-warn"
        >
          DEFECT 100
        </span>
        <span *ngIf="data.asset.defectComment">
          {{ data.asset.defectComment }}
        </span>
      </ng-container>
    </div>
  </div>

  <!-- AREA 3: BOTTOM ACTION BUTTONS -->
  <div class="row-3">
    <div class="keyboard">
      <button mat-icon-button color="accent" (click)="onClickKeyboard()">
        <mat-icon fontSet="material-symbols-outlined">keyboard</mat-icon>
      </button>
    </div>

    <div class="info">
      <button
        mat-icon-button
        color="accent"
        (click)="showDefectRecords()"
        [disabled]="!canShowDefectHistory"
      >
        <mat-icon fontSet="material-symbols-outlined">heart_broken</mat-icon>
      </button>
    </div>
  </div>

  <!-- AREA 4: BOTTOM -->
  <div class="row-4 form-field-density-2">
    <mat-form-field
      subscriptSizing="dynamic"
      appearance="outline"
      class="mt-tiny"
    >
      <mat-label>New Assessment</mat-label>
      <mat-select [(ngModel)]="selectedDefectState" required>
        <mat-select-trigger>
          <div class="flex-row align-items-center gap-normal">
            <span class="font-weight-500">{{ selectedDefectState?.name }}</span>
            <span class="tag">{{ selectedDefectState?.tag }}</span>
          </div>
        </mat-select-trigger>
        <mat-option
          *ngFor="let defectState of defectStates | keyvalue"
          [value]="defectState.value"
        >
          <div class="flex-row gap-tiny align-items-center">
            <span class="font-weight-500">{{ defectState.value.name }}</span>
            <span class="flex-grow-1"></span>
            <span class="tag">{{ defectState.value.tag }}</span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      subscriptSizing="dynamic"
      appearance="outline"
      class="mt-tiny"
    >
      <mat-label>Notes</mat-label>
      <input
        matInput
        type="text"
        [ngModel]="defectNotes"
        readonly
        placeholder="Add notes ..."
        required
      />
      <button matSuffix mat-icon-button (click)="editNotes()">
        <span class="material-symbols-outlined"> edit </span>
      </button>
    </mat-form-field>

    <div class="grid-2 mt-tiny">
      <button mat-stroked-button (click)="onClickClose()" [disabled]="activity">
        CANCEL
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="reportDefect()"
        [disabled]="activity || !selectedDefectState || !defectNotes"
      >
        <div class="flex-row gap-small"></div>
        <span> REPORT </span>
      </button>
    </div>
  </div>
</div>
