<!-- SHOW TENANT INFO IF MORE THAN ONE TENANT IS AVAILABLE -->
<ng-container *ngIf="selectionService.hasMultiTenantAccess">
  <a matRipple class="menu-a" (click)="onClickSwitchTenant()">
    <mat-icon>swap_horiz</mat-icon>
    <span>{{ selectionService.selectedTenant?.name }}</span>
  </a>
  <mat-divider></mat-divider>
</ng-container>

<a matRipple class="menu-a" routerLink="/home" (click)="dismiss()">
  <mat-icon>home</mat-icon>
  <span>Home</span>
</a>
<mat-divider></mat-divider>

<a matRipple class="menu-a" routerLink="/assetScan" (click)="dismiss()">
  <mat-icon fontSet="material-symbols-outlined">receipt</mat-icon>
  <span>Asset Info Scan</span>
</a>

<mat-divider></mat-divider>

<a matRipple class="menu-a" routerLink="/defects" (click)="dismiss()">
  <mat-icon>heart_broken</mat-icon>
  <span>Report Defect</span>
</a>
<mat-divider></mat-divider>

<a matRipple class="menu-a" routerLink="/inventory" (click)="dismiss()">
  <mat-icon fontSet="material-symbols-outlined">inventory</mat-icon>
  <span>Inventory Scan</span>
</a>
<mat-divider></mat-divider>

<!-- <a matRipple class="menu-a" routerLink="/qrcodes" (click)="dismiss()">
  <mat-icon>qr_code</mat-icon>
  <span>QR Code Builder</span>
</a>
<mat-divider></mat-divider> -->

<!-- <a matRipple class="menu-a" routerLink="/scanner" (click)="dismiss()">
  <mat-icon>sensors</mat-icon>
  <span>Scanner Test</span>
</a>
<mat-divider></mat-divider>  -->

<a matRipple class="menu-a" (click)="onClickSignOut()">
  <mat-icon>logout</mat-icon>
  <span>Sign out</span>
</a>
<mat-divider></mat-divider>

<div class="bottom">
  <span>&copy; {{ year }} Accenture</span>
  <div class="flex-row align-items-center gap-tiny white-space-nowrap">
    <a routerLink="/terms-of-use" (click)="dismiss()">Terms of Use</a>
    <mat-divider [vertical]="true"></mat-divider>
    <a routerLink="/privacy-statement" (click)="dismiss()">Privacy Statement</a>
  </div>
  <span>version {{ version }}</span>
  <span>mGAT</span>
</div>
