import {
  Component,
  Output,
  ViewChild,
  EventEmitter,
  Inject,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { LOCAL_STORAGE } from '../../common/localStorage';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { v4 } from 'uuid';
import { DateTime } from 'luxon';
import {
  NgScanAsset,
  NgScanAssetFactory,
  NgScanRealtimeConfig,
  NgScanRealtimeConfigFactory,
} from './ng-scan.model';
import { PlanOutput } from 'projects/shared/src/lib/graphql/output/planOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { ExtPlan, ExtPlanDetail } from './ext-plan.mode';
import {
  GetScannedAssetsQueryArgs,
  GetScannedAssetsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAssetPlanning';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { ScannedAssetOutput } from 'projects/shared/src/lib/graphql/output/scannedAssetOutput';
import { PhoneToastService } from '../../services/phone-toast.service';
import { PhoneToastDialogType } from '../../component-dialogs/phone-toast-dialog/phone-toast-dialog.component';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import { TenantActionInputCreate } from 'projects/shared/src/lib/graphql/input/create/tenantActionInputCreate';
import {
  CreateTenantActionsMutationArgs,
  CreateTenantActionsMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantAction';
import { FULL_FRAGMENT_TENANT_ACTION } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantAction';
import { MatDialog } from '@angular/material/dialog';
import {
  NgScanKeyboardDialogComponent,
  NgScanKeyboardDialogResult,
} from './ng-scan-keyboard-dialog/ng-scan-keyboard-dialog.component';
import {
  NgScanFromOrToDialogComponent,
  NgScanFromOrToDialogData,
  NgScanFromOrToDialogResult,
} from './ng-scan-from-or-to-dialog/ng-scan-from-or-to-dialog.component';
import { AssortmentService } from 'projects/shared/src/lib/services/assortment.service';
import { ActionLogicService } from 'projects/shared/src/lib/services/action-logic.service';
import { TenantActionOutput } from 'projects/shared/src/lib/graphql/output/tenantActionOutput';
import { ActionFrom } from 'projects/shared/src/lib/graphql/enums/actionFrom';
import { ActionTo } from 'projects/shared/src/lib/graphql/enums/actionTo';
import {
  NgScanNotesDialogComponent,
  NgScanNotesDialogData,
  NgScanNotesDialogResult,
} from './ng-scan-notes-dialog/ng-scan-notes-dialog.component';

type BookMode = 'PLAN' | 'REALTIME' | 'NONE';
type AssetLabel = 'unknown asset' | 'already booked' | 'not part of action';

type EvaluationResults = {
  isRealtimeBookModeAvailable: boolean;
  isPlanBookModeAvailable: boolean;
  availableBookModes: BookMode[];
  availableExtPlans: ExtPlan[] | undefined;
};

@Component({
  selector: 'app-ng-scan',
  templateUrl: './ng-scan.component.html',
  styleUrls: ['./ng-scan.component.scss'],
  providers: [NgScanAssetFactory, NgScanRealtimeConfigFactory],
})
export class NgScanComponent implements AfterViewInit, OnDestroy {
  /** 
   * string: planStepActionId (in case of a "plan booking")
   * 
   * boolean: booking was performed and successful (not cancelled)
  */
  @Output() onClose = new EventEmitter<[string | undefined, boolean]>();
  @ViewChild('action') scanner!: NgxScannerQrcodeComponent;
  devices: ScannerQRCodeDevice[] = [];
  uuid1 = v4();
  uuid2 = v4();
  activity = false;
  planCheckStart: any = DateTime.now().startOf('day').minus({ days: 5 }).toJSDate();
  planCheckEnd: any = DateTime.now().startOf('day').plus({ days: 30 }).toJSDate();
  readonly ngScanAssets = new Map<string, NgScanAsset>();
  selectedExtPlan: ExtPlan | undefined;
  selectedExtPlanDetail: ExtPlanDetail | undefined;
  evaluationResults: EvaluationResults | undefined;
  showMore = false;
  realtimeConfig = this.ngScanRealtimeConfigFactory.create();
  cameraIsOn = true;
  debugMode = false;
  debugEvents: string[] = [];

  get selectedMode() {
    return this.#selectedMode;
  }
  set selectedMode(value) {
    this.#selectedMode = value;
    if (value === 'PLAN' && (!this.selectedExtPlan || !this.selectedExtPlanDetail)) {
      this.selectedExtPlan = (this.evaluationResults?.availableExtPlans ?? [])[0];
      this.selectedExtPlanDetail = this.selectedExtPlan?.details[0];
    }
    if (value === 'REALTIME' || value === 'NONE') {
      this.selectedExtPlan = undefined;
      this.selectedExtPlanDetail = undefined;
    }
  }

  get canShowMore(): boolean {
    return !!this.selectedExtPlan && !!this.selectedExtPlanDetail;
  }

  get okNgScanAssets(): Map<string, NgScanAsset> {
    const filteredNgScanAssets = new Map<string, NgScanAsset>();
    for (const ngScanAsset of this.ngScanAssets) {
      if (typeof ngScanAsset[1].asset === 'undefined') {
        // The scanned asset is not a valid asset in the tenant.
        // SKIP
        continue;
      }

      if (this.selectedMode === 'PLAN' && this.selectedExtPlanDetail) {
        // The scanned asset must not already be booked.
        if (
          this.selectedExtPlanDetail.scannedAssets
            .filter((x) => x.assetBooked === true)
            .map((x) => x.assetId)
            .includes(ngScanAsset[0])
        ) {
          continue;
        } else if (
          !this.selectedExtPlanDetail.scannedAssets.map((x) => x.assetId).includes(ngScanAsset[0])
        ) {
          continue;
        }
      }

      filteredNgScanAssets.set(ngScanAsset[0], ngScanAsset[1]);
    }

    return filteredNgScanAssets;
  }

  get ngScanAssetsLabels(): Map<string, AssetLabel[]> {
    const result = new Map<string, AssetLabel[]>();
    for (const ngScanAsset of this.ngScanAssets) {
      if (typeof ngScanAsset[1].asset === 'undefined') {
        result.set(ngScanAsset[0], ['unknown asset']);
        continue;
      }

      if (this.selectedMode === 'PLAN' && this.selectedExtPlanDetail) {
        // Check if the ngScanAsset is already booked
        if (
          this.selectedExtPlanDetail.scannedAssets
            .filter((x) => x.assetBooked === true)
            .map((x) => x.assetId)
            .includes(ngScanAsset[0])
        ) {
          result.set(ngScanAsset[0], ['already booked']);
          continue;
        } else if (
          !this.selectedExtPlanDetail.scannedAssets.map((x) => x.assetId).includes(ngScanAsset[0])
        ) {
          result.set(ngScanAsset[0], ['not part of action']);
          continue;
        }
      }

      result.set(ngScanAsset[0], []);
    }

    return result;
  }

  get canBook(): boolean {
    return true; // TODO
  }

  get selectedDevice() {
    return this.#selectedDevice;
  }
  set selectedDevice(value: ScannerQRCodeDevice | undefined) {
    this.#selectedDevice = value;
    if (value) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_DEVICE_ID, value.deviceId);

      this.scanner.isBeep = false;
      this.scanner.vibrate = 0;
      this.scanner.playDevice(value.deviceId);
    }
  }

  #selectedDevice: ScannerQRCodeDevice | undefined;
  #selectedMode: BookMode = 'REALTIME';

  constructor(
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private ngScanAssetFactory: NgScanAssetFactory,
    private ngScanRealtimeConfigFactory: NgScanRealtimeConfigFactory,
    public localeService: LocaleService,
    private _apollo: Apollo,
    private _toastService: PhoneToastService,
    private _matDialog: MatDialog,
    public assortmentService: AssortmentService,
    private _actionLogicService: ActionLogicService
  ) {}

  ngAfterViewInit(): void {
    this.#startCamera();
  }

  ngOnDestroy(): void {
    this.scanner.stop();
  }

  toggleCamera() {
    if (this.cameraIsOn) {
      this.scanner.stop();
    } else {
      this.#startCamera();
    }

    this.cameraIsOn = !this.cameraIsOn;
    (document.activeElement as HTMLElement)?.blur();
  }

  realtimeEdit(direction: 'from' | 'to') {
    if (!this.realtimeConfig.selectedActionType) {
      return;
    }

    const data: NgScanFromOrToDialogData = {
      direction,
      actionType: this.realtimeConfig.selectedActionType,
      selectedFromType: direction === 'from' ? this.realtimeConfig.selectedFromType : undefined,
      fromDetails: direction === 'from' ? this.realtimeConfig.fromDetails : undefined,
      selectedToType: direction === 'to' ? this.realtimeConfig.selectedToType : undefined,
      toDetails: direction === 'to' ? this.realtimeConfig.toDetails : undefined,
    };

    const dialog = this._matDialog.open(NgScanFromOrToDialogComponent, {
      data,
      autoFocus: false,
      width: '90%',
      maxWidth: 704,
    });

    dialog.afterClosed().subscribe((result: NgScanFromOrToDialogResult) => {
      (document.activeElement as HTMLElement)?.blur();

      if (!result) {
        return;
      }

      if (direction === 'from') {
        this.realtimeConfig.selectedFromType = result.selectedFromType;
        this.realtimeConfig.fromDetails = result.fromDetails;
      } else {
        this.realtimeConfig.selectedToType = result.selectedToType;
        this.realtimeConfig.toDetails = result.toDetails;
      }
    });
  }

  realtimeEditNotes() {
    const data: NgScanNotesDialogData = {
      notes: this.realtimeConfig.description,
    };

    const dialog = this._matDialog.open(NgScanNotesDialogComponent, {
      data,
      width: '90%',
      maxWidth: 704,
    });

    dialog.afterClosed().subscribe((result: NgScanNotesDialogResult | undefined) => {
      (document.activeElement as HTMLElement)?.blur();

      if (!result) {
        return;
      }

      this.realtimeConfig.description = result.notes;
    });
  }

  onClickClose() {
    this.scanner.stop();
    this.onClose.emit([undefined, false]);
  }

  onClickKeyboard() {
    const dialog = this._matDialog.open(NgScanKeyboardDialogComponent);

    dialog.afterClosed().subscribe((result: NgScanKeyboardDialogResult) => {
      if (!result) {
        return;
      }

      (document.activeElement as HTMLElement)?.blur();

      this.#handleAssetId(result);
    });
  }

  onEventQrCode(events: ScannerQRCodeResult[]) {
    this.debugEvents.unshift('onEventQrCode: ' + JSON.stringify(events));
    for (const id of events.map((x) => x.value)) {
      this.#handleAssetId(id);
    }
  }

  onClickBook() {
    if (this.selectedMode == 'NONE') {
      return;
    }

    if (this.selectedMode == 'PLAN') {
      this.#bookInPlanMode();
      return;
    }

    if (this.selectedMode == 'REALTIME') {
      this.#bookInRealtimeMode();
    }
  }

  deleteAsset(id: string) {
    this.ngScanAssets.delete(id);
    this.#evaluateModeAndSelections();
  }

  async onPlanCheckDatePickerClosed() {
    const [start, end] = this.#getPlanCheckDuration();

    try {
      for (const ngScanAsset of this.ngScanAssets) {
        await ngScanAsset[1].evaluate(start, end);
      }
      this.#evaluateModeAndSelections();
    } catch (error) {
      this._toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    }
  }

  #startCamera() {
    const savedSelectedDeviceId = localStorage.getItem(LOCAL_STORAGE.SELECTED_DEVICE_ID);

    this.scanner.start((devices: ScannerQRCodeDevice[] | undefined) => {
      if (typeof devices === 'undefined' || devices.length === 0) {
        return;
      }

      this.devices = devices;

      if (savedSelectedDeviceId) {
        this.selectedDevice = this.devices.find((x) => x.deviceId === savedSelectedDeviceId);
      } else {
        // First run. Chose the first device.
        this.selectedDevice = this.devices[0];
      }
    });
  }

  async #bookInPlanMode() {
    if (!this.selectedExtPlanDetail) {
      return;
    }

    try {
      this.activity = true;

      const data: TenantActionInputCreate = {
        actionTypeId: this.selectedExtPlanDetail.planStepAction.actionTypeId,
        planStepActionId: this.selectedExtPlanDetail.planStepAction.id,
        planId: this.selectedExtPlanDetail.planStep.planId,
        description: this.selectedExtPlanDetail.planStepAction.description,
        fromUserOid: this.selectedExtPlanDetail.planStepAction.fromUserOid,
        fromLocationId: this.selectedExtPlanDetail.planStepAction.fromLocationId,
        fromMail: this.selectedExtPlanDetail.planStepAction.fromMail,
        fromOther: this.selectedExtPlanDetail.planStepAction.fromOther,
        toUserOid: this.selectedExtPlanDetail.planStepAction.toUserOid,
        toLocationId: this.selectedExtPlanDetail.planStepAction.toLocationId,
        toMail: this.selectedExtPlanDetail.planStepAction.toMail,
        toOther: this.selectedExtPlanDetail.planStepAction.toOther,
        transitLocationId: this.selectedExtPlanDetail.planStepAction.transitLocationId,
      };

      const variables: CreateTenantActionsMutationArgs = {
        data,
        assetIds: Array.from(this.ngScanAssets.keys()),
      };

      const result = await firstValueFrom(
        this._apollo.mutate<CreateTenantActionsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            mutation CreateTenantActions($assetIds: [String!]!, $data: TenantActionInputCreate!) {
              createTenantActions(assetIds: $assetIds, data: $data) {
                ...FullFragmentTenantAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.scanner.stop();

      const actionName = this.selectedExtPlanDetail.planStepAction.actionType?.name.toUpperCase();

      this._toastService.show(
        PhoneToastDialogType.Success,
        `You successfully booked the planned action <b>${actionName}</b> for the ${
          this.ngScanAssets.size
        } scanned ${this.ngScanAssets.size === 1 ? 'asset' : 'assets'}.`
      );
      const asd = result.data?.createTenantActions;
      this.onClose.emit([this.selectedExtPlanDetail.planStepAction.id, true]);
    } catch (error) {
      this._toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    } finally {
      this.activity = false;
    }
  }

  async #bookInRealtimeMode() {
    if (!this.realtimeConfig.selectedActionType) {
      return;
    }

    try {
      this.activity = true;

      const data: TenantActionInputCreate = {
        actionTypeId: this.realtimeConfig.selectedActionType.id,
        planStepActionId: null,
        planId: null,
        description: this.realtimeConfig.description,
        fromUserOid:
          this.realtimeConfig.selectedFromType === ActionFrom.User
            ? this.realtimeConfig.fromDetails
            : null,
        fromLocationId:
          this.realtimeConfig.selectedFromType === ActionFrom.Location
            ? this.realtimeConfig.fromDetails
            : null,
        fromMail:
          this.realtimeConfig.selectedFromType === ActionFrom.Mail
            ? this.realtimeConfig.fromDetails
            : null,
        fromOther:
          this.realtimeConfig.selectedFromType === ActionFrom.Other
            ? this.realtimeConfig.fromDetails
            : null,
        toUserOid:
          this.realtimeConfig.selectedToType === ActionTo.User
            ? this.realtimeConfig.toDetails
            : null,
        toLocationId:
          this.realtimeConfig.selectedToType === ActionTo.Location
            ? this.realtimeConfig.toDetails
            : null,
        toMail:
          this.realtimeConfig.selectedToType === ActionTo.Mail
            ? this.realtimeConfig.toDetails
            : null,
        toOther:
          this.realtimeConfig.selectedToType === ActionTo.Other
            ? this.realtimeConfig.toDetails
            : null,
        transitLocationId: null,
      };

      const variables: CreateTenantActionsMutationArgs = {
        data,
        assetIds: Array.from(this.ngScanAssets.keys()),
      };

      const result = await firstValueFrom(
        this._apollo.mutate<CreateTenantActionsMutationRoot>({
          mutation: gql`
            ${FULL_FRAGMENT_TENANT_ACTION}
            mutation CreateTenantActions($assetIds: [String!]!, $data: TenantActionInputCreate!) {
              createTenantActions(assetIds: $assetIds, data: $data) {
                ...FullFragmentTenantAction
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.scanner.stop();

      const actionName = this.realtimeConfig.selectedActionType.name.toUpperCase();

      this._toastService.show(
        PhoneToastDialogType.Success,
        `You successfully booked a realtime action <b>${actionName}</b> for the ${
          this.ngScanAssets.size
        } scanned ${this.ngScanAssets.size === 1 ? 'asset' : 'assets'}.`
      );
      this.onClose.emit([undefined, true]);
    } catch (error) {
      this._toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    } finally {
      this.activity = false;
    }
  }

  #handleAssetId(id: string) {
    if (this.ngScanAssets.has(id)) {
      return; // nothing to do
    }

    this.activity = true;

    const ngScanAsset = this.ngScanAssetFactory.create(id);
    this.ngScanAssets.set(id, ngScanAsset);
    ngScanAsset.evaluationFinished.subscribe({
      next: (evaluation) => {
        this.activity = false;
        this.#evaluateModeAndSelections();
      },
      error: (error) => {
        this.activity = false;
        this._toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
      },
    });
    const [start, end] = this.#getPlanCheckDuration();
    ngScanAsset.evaluate(start, end);
  }

  async #evaluateModeAndSelections() {
    try {
      // We expect to work mit at least one scanned asset.
      if (this.ngScanAssets.size === 0) {
        this.selectedExtPlanDetail = undefined;
        this.selectedExtPlan = undefined;
        this.selectedMode = 'REALTIME';
        this.evaluationResults = undefined;
        return;
      }

      const availableBookModes: BookMode[] = [];

      // Check, if REALTIME (booking mode) is available.
      // Requirements:
      // - All assets must be valid tenant assets.
      // - All assets must currently not be on a plan.
      const isREALTIMEAvailable = Array.from(this.ngScanAssets.values()).every(
        (x) => typeof x.asset !== 'undefined' && x.asset.currentPlanName == null
      );
      if (isREALTIMEAvailable) {
        availableBookModes.push('REALTIME');

        const assetActions = Array.from(this.ngScanAssets.values())
          .filter((x) => typeof x.action !== 'undefined')
          .map((x) => x.action) as TenantActionOutput[];

        const validNextActions = this._actionLogicService.determineValidNextActions(assetActions);
        this.realtimeConfig.validNextActions = validNextActions;

        if (validNextActions.length > 0) {
          // Just pre-select everything based on the FIRST valid next action.

          const actionTypes = await this.assortmentService.getActionTypesAsync();
          this.realtimeConfig.selectedActionType = actionTypes.find(
            (x) => x.id === validNextActions[0][0]
          );

          this.realtimeConfig.selectedFromType = validNextActions[0][1];
          this.realtimeConfig.fromDetails = validNextActions[0][2];
        } else {
          this.realtimeConfig.selectedActionType = undefined;
          this.realtimeConfig.selectedFromType = undefined;
          this.realtimeConfig.fromDetails = undefined;
        }

        this.realtimeConfig.selectedToType = undefined;
        this.realtimeConfig.toDetails = undefined;
      }

      // Check, if PLAN (booking mode) is available.
      // Requirements:
      // - All assets must be valid tenant assets.
      // - All assets have at least one plan in common.
      const availablePlans = new Map<string, PlanOutput>();
      const intersectionPlans: PlanOutput[] = [];
      for (let plans of Array.from(this.ngScanAssets.values()).map((x) => x.plans)) {
        plans.forEach((x) => {
          if (!availablePlans.has(x.id)) {
            availablePlans.set(x.id, x);
          }
        });
      }
      for (const plan of availablePlans) {
        let hits = 0;
        for (const ngScanAsset of Array.from(this.ngScanAssets.values())) {
          if (ngScanAsset.plans.map((x) => x.id).includes(plan[0])) {
            hits++;
          }
        }

        if (hits === this.ngScanAssets.size) {
          intersectionPlans.push(plan[1]);
        }
      }
      const isPLANAvailable = intersectionPlans.length > 0;
      if (isPLANAvailable) {
        availableBookModes.push('PLAN');
      }

      if (availableBookModes.length === 0) {
        availableBookModes.push('NONE');
      }

      const extPlans = await this.#createExtPlans(intersectionPlans);
      // Combine everything and prepare results.
      const results: EvaluationResults = {
        isRealtimeBookModeAvailable: isREALTIMEAvailable,
        isPlanBookModeAvailable: isPLANAvailable,
        availableBookModes,
        availableExtPlans: extPlans,
      };

      // Now check if we have any selection that needs to be changed
      // based on the calculated results.
      this.evaluationResults = results;

      if (!this.selectedMode) {
        // There was no mode selected previously.
        // Chose the first newly available mode.
        // In cas that the first available mode is "PLAN"
        // => Select first available extPlan.
        this.selectedMode = results.availableBookModes[0] ?? 'NONE';
        if (this.selectedMode === 'PLAN') {
          this.selectedExtPlan = extPlans[0];
          this.selectedExtPlanDetail = this.selectedExtPlan?.details[0];
        } else {
          this.selectedExtPlanDetail = undefined;
          this.selectedExtPlan = undefined;
        }
        return;
      }

      if (!results.availableBookModes.includes(this.selectedMode)) {
        // The previously selected mode is NOT available anymore.
        this.selectedMode = results.availableBookModes[0] ?? 'NONE';
        if (this.selectedMode === 'PLAN') {
          this.selectedExtPlan = extPlans[0];
          this.selectedExtPlanDetail = this.selectedExtPlan?.details[0];
        } else {
          this.selectedExtPlanDetail = undefined;
          this.selectedExtPlan = undefined;
        }
        return;
      }

      // The previously selectedMode is still available
      // Don't change anything ???
    } catch (error) {
      this._toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    }
  }

  #getPlanCheckDuration(): [Date, Date] {
    // The "planCheckStart" and "planCheckEnd" objects are either a JS Date (if they were
    // not changed by the user) or a luxon DateTime object. We have to handle both.

    const planCheckStartTest = DateTime.fromJSDate(this.planCheckStart);
    const planCheckStartDateTime = planCheckStartTest.isValid
      ? planCheckStartTest
      : (this.planCheckStart as DateTime);

    const planCheckEndTest = DateTime.fromJSDate(this.planCheckEnd);
    const planCheckEndDateTime = planCheckEndTest.isValid
      ? planCheckEndTest
      : (this.planCheckEnd as DateTime);

    return [planCheckStartDateTime.toJSDate(), planCheckEndDateTime.endOf('day').toJSDate()];
  }

  async #createExtPlans(plans: PlanOutput[]): Promise<ExtPlan[]> {
    const extPlans: ExtPlan[] = [];

    for (let plan of plans) {
      const newExtPlan: ExtPlan = {
        plan,
        details: [],
      };
      extPlans.push(newExtPlan);

      for (let planStep of plan.planSteps ?? []) {
        for (let planStepAction of planStep.planStepActions ?? []) {
          const newExtPlanDetail: ExtPlanDetail = {
            planStep,
            planStepAction,
            scannedAssets: [],
          };

          // Get scanned Assets
          const scannedAssets = await this.#fetchScannedAssets(planStepAction.id);
          newExtPlanDetail.scannedAssets = scannedAssets;
          newExtPlan.details.push(newExtPlanDetail);
        }
      }

      // Sort
      newExtPlan.details = newExtPlan.details.sortBy((x) => x.planStepAction.date);
    }

    return extPlans;
  }

  async #fetchScannedAssets(planStepActionId: string): Promise<ScannedAssetOutput[]> {
    const variables: GetScannedAssetsQueryArgs = {
      planStepActionId,
    };

    const result = await firstValueFrom(
      this._apollo.query<GetScannedAssetsQueryRoot>({
        query: gql`
          query GetScannedAssets($planStepActionId: String!) {
            getScannedAssets(planStepActionId: $planStepActionId) {
              assetId
              assetBooked
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    return result.data.getScannedAssets;
  }
}
