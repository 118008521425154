import { Component } from '@angular/core';
import { SelectionService } from '../../services/selection.service';
import { TenantOutput } from 'projects/shared/src/lib/graphql/output/tenantOutput';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-tenant-dialog',
  templateUrl: './switch-tenant-dialog.component.html',
  styleUrls: ['./switch-tenant-dialog.component.scss'],
})
export class SwitchTenantDialogComponent {
  constructor(
    public selectionService: SelectionService,
    private _dialogRef: MatDialogRef<SwitchTenantDialogComponent>
  ) {}

  onClickTenant(tenant: TenantOutput) {
    this._dialogRef.close(tenant);
  }
}
