import { Injectable } from '@angular/core';
import { ActionTypeOutput } from '../graphql/output/actionTypeOutput';
import { ActionType } from '../graphql/enums/actionType';
import { ActionFrom, actionFroms } from '../graphql/enums/actionFrom';
import { ActionTo, actionTos } from '../graphql/enums/actionTo';
import { TenantActionOutput } from '../graphql/output/tenantActionOutput';

export type MeaningfulNextData = {
  nextActionTypeId: number | undefined;
  nextFrom: ActionFrom | undefined;
  fromDetails: string | undefined;
  nextTo: ActionTo | undefined;
  toDetails: string | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class ActionLogicService {
  readonly validActionTransitions: [ActionType, ActionType][] = [
    [ActionType.PickUp, ActionType.Deposit],
    [ActionType.PickUp, ActionType.Handover],
    [ActionType.Deposit, ActionType.PickUp],
    [ActionType.Handover, ActionType.Deposit],
    [ActionType.Handover, ActionType.Handover],
  ];

  readonly validActionFromTypes: [ActionType, ActionFrom[]][] = [
    [ActionType.PickUp, [ActionFrom.Location]],
    [ActionType.Deposit, [ActionFrom.User, ActionFrom.Mail, ActionFrom.Other]],
    [ActionType.Handover, [ActionFrom.User, ActionFrom.Mail, ActionFrom.Other]],
  ];

  readonly validActionToTypes: [ActionType, ActionTo[]][] = [
    [ActionType.PickUp, [ActionTo.User, ActionTo.Mail, ActionTo.Other]],
    [ActionType.Deposit, [ActionTo.Location]],
    [ActionType.Handover, [ActionTo.User, ActionTo.Mail, ActionTo.Other]],
  ];

  constructor() {}

  determineValidFromTypes(actionType: ActionTypeOutput): Map<number, string> {
    const result = new Map<number, string>();
    const validFromTypes = this.validActionFromTypes.find((x) => x[0] === actionType.id)?.[1] ?? [];

    for (const validFromType of validFromTypes) {
      result.set(validFromType, actionFroms.get(validFromType) ?? 'na');
    }

    return result;
  }

  determineValidToTypes(actionType: ActionTypeOutput): Map<number, string> {
    const result = new Map<number, string>();
    const validToTypes = this.validActionToTypes.find((x) => x[0] === actionType.id)?.[1] ?? [];

    for (const validToType of validToTypes) {
      result.set(validToType, actionTos.get(validToType) ?? 'na');
    }

    return result;
  }

  determineValidNextActions(actions: TenantActionOutput[]): [ActionType, ActionFrom, string][] {
    const results: [ActionType, ActionFrom, string][] = [];

    // The TO TYPEs and TO DETAILS for all actions must be identical.
    // Example (2 actions):
    // PICKUP TO User A
    // HANDOVER TO User A
    // => Allowed next actions: HANDOVER and DEPOSIT (FROM User A)

    const actionsData = actions.map((x) => this.#getActionData(x));

    if (new Set(actionsData.map((x) => x[3])).size > 1) {
      // There are more than one TO TYPEs.
      return results;
    }

    if (new Set(actionsData.map((x) => x[4])).size > 1) {
      // There are more than one TO details.
      return results;
    }

    // Ok.
    const actionTypes = Array.from(new Set(actionsData.map((x) => x[0])));
    const validTransitions = this.validActionTransitions.filter((x) => actionTypes.includes(x[0]));
    for (const validTransition of validTransitions) {
      let actionFrom: ActionFrom;
      if (actionsData[0][3] === ActionTo.User) {
        actionFrom = ActionFrom.User;
      } else if (actionsData[0][3] === ActionTo.Location) {
        actionFrom = ActionFrom.Location;
      } else if (actionsData[0][3] === ActionTo.Mail) {
        actionFrom = ActionFrom.Mail;
      } else {
        actionFrom = ActionFrom.Other;
      }

      results.push([validTransition[1], actionFrom, actionsData[0][4]]);
    }

    return results;
  }

  // determineValidNextData(actions: TenantActionOutput[]): MeaningfulNextData | undefined {
  //   const noData: MeaningfulNextData = {
  //     nextActionTypeId: undefined,
  //     nextFrom: undefined,
  //     fromDetails: undefined,
  //     nextTo: undefined,
  //     toDetails: undefined,
  //   };

  //   if (new Set(actions.map((x) => x.actionTypeId)).size > 1) {
  //     // Multiple different action types.
  //     // No valid next data can be calculated.
  //     return undefined;
  //   }

  //   const actionsToData = actions.map((x) => this.#getActionToData(x));
  //   if (new Set(actionsToData.map((x) => x[0])).size > 1) {
  //     // Multiple different TO types.
  //     // No valid next data can be calculated
  //     return undefined;
  //   }

  //   if (new Set(actionsToData.map((x) => x[1])).size > 1) {
  //     // Multiple different TO values.
  //     // No valid next data can be calculated
  //   }

  //   // All actions are of the same type, have the same TO type
  //   // and the same TO value.

  //   const validActionTransition = this.validActionTransitions.find(
  //     (x) => x[0] === actions[0].actionTypeId
  //   );
  //   if (!validActionTransition) {
  //     return undefined;
  //   }

  //   let actionFrom: ActionFrom;
  //   switch (actionsToData[0][0]) {
  //     case ActionTo.User:
  //       actionFrom = ActionFrom.User;
  //       break;

  //     case ActionTo.Location:
  //       actionFrom = ActionFrom.Location;
  //       break;

  //     case ActionTo.Mail:
  //       actionFrom = ActionFrom.Mail;
  //       break;

  //     default:
  //       actionFrom = ActionFrom.Other;
  //       break;
  //   }

  //   // Handle a unique transition case
  //   // PICKUP => DEPOSIT
  //   // In this case the TO details should be the initial
  //   // FROM details (but only if all initial FROMs are identical).
  //   let specialToLocationId: string | undefined;
  //   if (
  //     validActionTransition[0] === ActionType.PickUp &&
  //     validActionTransition[1] == ActionType.Deposit
  //   ) {
  //     const actionsFromData = actions.map((x) => this.#getActionFromData(x));
  //     if (new Set(actionsFromData.map((x) => x[1])).size === 1) {
  //       specialToLocationId = actionsFromData[0][1];
  //     }
  //   }

  //   const nextData: MeaningfulNextData = {
  //     nextActionTypeId: validActionTransition[1],
  //     nextFrom: actionFrom,
  //     fromDetails: actionsToData[0][1],
  //     nextTo: specialToLocationId ? ActionTo.Location : undefined,
  //     toDetails: specialToLocationId ? specialToLocationId : undefined,
  //   };

  //   return nextData;
  // }

  #getActionFromData(action: TenantActionOutput): [ActionFrom, string] {
    if (action.fromUserOid) {
      return [ActionFrom.User, action.fromUserOid];
    }

    if (action.fromLocationId) {
      return [ActionFrom.Location, action.fromLocationId];
    }

    if (action.fromMail) {
      return [ActionFrom.Mail, action.fromMail];
    }

    return [ActionFrom.Other, action.fromOther ?? ''];
  }

  #getActionToData(action: TenantActionOutput): [ActionTo, string] {
    if (action.toUserOid) {
      return [ActionTo.User, action.toUserOid];
    }

    if (action.toLocationId) {
      return [ActionTo.Location, action.toLocationId];
    }

    if (action.toMail) {
      return [ActionTo.Mail, action.toMail];
    }

    return [ActionTo.Other, action.fromOther ?? ''];
  }

  #getActionData(action: TenantActionOutput): [ActionType, ActionFrom, string, ActionTo, string] {
    let actionFrom: ActionFrom;
    let actionFromDetails: string = '';
    let actionTo: ActionTo;
    let actionToDetails: string = '';

    if (action.fromUserOid) {
      actionFrom = ActionFrom.User;
      actionFromDetails = action.fromUserOid;
    } else if (action.fromLocationId) {
      actionFrom = ActionFrom.Location;
      actionFromDetails = action.fromLocationId;
    } else if (action.fromMail) {
      actionFrom = ActionFrom.Mail;
      actionFromDetails = action.fromMail;
    } else {
      actionFrom = ActionFrom.Other;
      actionFromDetails = action.fromOther ?? 'na';
    }

    if (action.toUserOid) {
      actionTo = ActionTo.User;
      actionToDetails = action.toUserOid;
    } else if (action.toLocationId) {
      actionTo = ActionTo.Location;
      actionToDetails = action.toLocationId;
    } else if (action.toMail) {
      actionTo = ActionTo.Mail;
      actionToDetails = action.toMail;
    } else {
      actionTo = ActionTo.Other;
      actionToDetails = action.toOther ?? 'na';
    }

    return [action.actionTypeId, actionFrom, actionFromDetails, actionTo, actionToDetails];
  }
}
