<!-- *********************** -->
<!-- EXPANSION TILE TEMPLATE -->
<!-- *********************** -->
<ng-template #expansionItem let-plannedAction="planningAction">
  <app-expansion-tile
    [backgroundColor]="
      (planStepActionScanData.get(plannedAction.planStepActionId)?.booked ??
        0) >
        0 &&
      planStepActionScanData.get(plannedAction.planStepActionId)?.booked ===
        planStepActionScanData.get(plannedAction.planStepActionId)
          ?.scannedAssets?.length
        ? 'lightgreen'
        : null
    "
  >
    <!-- Header -->
    <app-expansion-tile-header>
      <div class="flex-column">
        <div class="flex-row align-items-center gap-small">
          <span class="font-size-16 black nowrap">
            {{
              plannedAction.planStepAction?.date
                | date : localeService.datetimePipeString(locale)
            }}
          </span>
          <span class="font-size-16 black">
            <b>
              <span class="uppercase">{{
                plannedAction.planStepAction.actionType.name
              }}</span>
            </b>
          </span>
          <span
            *ngIf="plannedAction.planStepAction.transitLocationId"
            class="nowrap"
            style="text-overflow: ellipsis; overflow-x: hidden; overflow-y: hidden;"
          >
            <lib-location
              [locationId]="plannedAction.planStepAction.transitLocationId"
            ></lib-location>
          </span>
        </div>

        <div class="mt-small h-grid-2 gap-small font-size-12 line-height-14">
          <div class="flex-row gap-tiny overflow-x-hidden">
            <span class="font-weight-500">PLAN:</span>

            <span class="ellipsis">{{ plannedAction.plan?.name }}</span>
          </div>

          <div class="flex-row gap-tiny overflow-x-hidden">
            <span class="font-weight-500">STEP:</span>
            <span class="ellipsis">{{ plannedAction.planStep?.name }}</span>
          </div>
        </div>

        <div
          *ngIf="plannedAction.planStepAction?.description"
          class="mt-small flex-row gap-tiny font-size-12 line-height-14 overflow-x-hidden"
        >
          <span class="font-weight-500">NOTES:</span>
          <span class="ellipsis">
            {{ plannedAction.planStepAction?.description }}
          </span>
        </div>
      </div>
    </app-expansion-tile-header>

    <!-- Content -->
    <app-expansion-tile-content>
      <div class="mt-small flex-column font-size-12 line-height-14">
        <ng-container *ngTemplateOutlet="bookedDataAvailableElement">
        </ng-container>

        <div class="mt-small">
          <mat-divider></mat-divider>
        </div>

        <div class="mt-small h-grid-2 gap-small">
          <div class="flex-row gap-tiny align-items-center overflow-x-hidden">
            <span class="font-weight-500">FROM:</span>
            <app-action-details
              class="ellipsis"
              fromOrTo="from"
              [action]="plannedAction.planStepAction"
              variant="phone"
            >
            </app-action-details>
          </div>
          <div class="flex-row gap-tiny align-items-center overflow-x-hidden">
            <span class="font-weight-500">TO:</span>
            <app-action-details
              class="ellipsis"
              fromOrTo="to"
              [action]="plannedAction.planStepAction"
              variant="phone"
            >
            </app-action-details>
          </div>
        </div>

        <div
          *ngIf="plannedAction.planStep?.description"
          class="flex-column mt-small"
        >
          <span class="font-weight-500">STEP INFO:</span>
          <span>{{ plannedAction.planStep?.description }}</span>
        </div>

        <div
          *ngIf="plannedAction.plan?.description"
          class="flex-column mt-small"
        >
          <span class="font-weight-500">PLAN INFO:</span>
          <span>{{ plannedAction.plan?.description }}</span>
        </div>

        <div class="mt-normal flex-row justify-content-space-evenly">
          <div class="flex-column align-items-center">
            <button
              mat-mini-fab
              color="accent"
              (click)="onClickScan(plannedAction)"
              [disabled]="
                (planStepActionScanData.get(plannedAction.planStepActionId)
                  ?.booked ??
                  0) >
                  0 &&
                planStepActionScanData.get(plannedAction.planStepActionId)
                  ?.booked ===
                  planStepActionScanData.get(plannedAction.planStepActionId)
                    ?.scannedAssets?.length
              "
            >
              <mat-icon>crop_free</mat-icon>
            </button>
            <span class="mt-tiny">scan & book</span>
          </div>
        </div>
      </div>
    </app-expansion-tile-content>

    <!-- Footer -->
    <app-expansion-tile-footer>
      <span class="font-size-12 align-self-end">
        <ng-container
          *ngIf="
            planStepActionScanData.get(plannedAction.planStepActionId)
              ?.state === 1;
            else bookedDataAvailableElement
          "
        >
          <mat-icon class="spinner">donut_large</mat-icon>
        </ng-container>

        <ng-template #bookedDataAvailableElement>
          <ng-container
            *ngIf="
              planStepActionScanData.get(plannedAction.planStepActionId)
                ?.state === 2
            "
          >
            <!-- // LOADING OK -->
            <div class="flex-row align-items-center">
              <span>
                {{
                  planStepActionScanData.get(plannedAction.planStepActionId)
                    ?.booked
                }}
              </span>
              <span>/</span>
              <span>
                {{
                  planStepActionScanData.get(plannedAction.planStepActionId)
                    ?.scannedAssets?.length
                }}
              </span>
              <span> &nbsp; assets booked</span>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              planStepActionScanData.get(plannedAction.planStepActionId)
                ?.state === 3
            "
          >
            error loading booked information
          </ng-container>
        </ng-template>
      </span>
    </app-expansion-tile-footer>
  </app-expansion-tile>
</ng-template>

<div class="content">
  <!-- ############################## MY PLANS ############################## -->
  <ng-container *ngIf="(myPlans?.length ?? -1) >= 0">
    <div class="section">
      <span>My Plans</span>
    </div>

    <span class="mat-body mb-normal mt-normal padding-content">
      Please find below
      <span class="color-black font-weight-500">
        all plans that you are responsible for
      </span>
      during the configured period of time. Adjust the time period if necessary.
    </span>

    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      class="padding-content"
    >
      <mat-date-range-input [rangePicker]="myPlansDurationPicker">
        <input
          matStartDate
          placeholder="Start date"
          [(ngModel)]="myPlansCheckStartDate"
          readonly
        />
        <input
          matEndDate
          placeholder="End date"
          [(ngModel)]="myPlansCheckEndDate"
          readonly
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="myPlansDurationPicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker
        #myPlansDurationPicker
        [touchUi]="true"
        (closed)="onMyPlansDatePickerClosed()"
      ></mat-date-range-picker>
    </mat-form-field>

    <lib-spinner
      *ngIf="myPlansLoading; else myPlansLoadedElement"
      class="mt-normal ml-small"
    >
    </lib-spinner>
    <ng-template #myPlansLoadedElement>
      <span
        *ngIf="myPlans?.length === 0; else plansFoundElement"
        class="mt-normal padding-content mat-body"
      >
        There are no plans that you are responsible for between
        <span class="font-weight-500">
          {{
            myPlansCheckStartDate
              | date : localeService.datetimePipeString(locale)
          }}
        </span>
        and
        <span class="font-weight-500">
          {{
            myPlansCheckEndDate
              | date : localeService.datetimePipeString(locale)
          }} </span
        >.
      </span>
      <ng-template #plansFoundElement>
        <div class="padding-content mt-normal flex-column gap-small">
          <ng-container *ngFor="let plan of myPlans; index as i">
            <div class="flex-column gap-tiny" [class.mt-small]="i > 0">
              <span class="font-weight-500">{{ plan.name }}</span>
              <span class="font-size-12">
                {{
                  plan.planStart
                    | date : localeService.datetimePipeString(locale)
                }}
                -
                {{
                  plan.planEnd | date : localeService.datetimePipeString(locale)
                }}
              </span>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-template>

    <div class="mt-huge"></div>
  </ng-container>

  <!-- ############################## MY PLANNED ACTIONS ############################## -->
  <div class="section">
    <span>My Planned Actions</span>
  </div>

  <span class="mat-body mb-normal mt-normal padding-content">
    Please find below
    <span class="color-black font-weight-500">
      all actions that you are planned on
    </span>
    for the configured period of time. Adjust the time period if necessary.
  </span>

  <mat-form-field
    appearance="outline"
    subscriptSizing="dynamic"
    class="padding-content"
  >
    <mat-date-range-input [rangePicker]="durationPicker">
      <input
        matStartDate
        placeholder="Start date"
        [(ngModel)]="sinceDay"
        readonly
      />
      <input
        matEndDate
        placeholder="End date"
        [(ngModel)]="untilDay"
        readonly
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      matIconSuffix
      [for]="durationPicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker
      #durationPicker
      [touchUi]="true"
      (closed)="onDatePickerClosed()"
    ></mat-date-range-picker>
  </mat-form-field>

  <lib-spinner
    *ngIf="myPlannedActionsLoading; else myPlannedActionsLoadedElement"
    class="mt-normal ml-small"
  >
  </lib-spinner>
  <ng-template #myPlannedActionsLoadedElement>
    <span
      *ngIf="myPlanningActions.length === 0; else actionsFoundElement"
      class="mt-normal padding-content mat-body"
    >
      There are no actions planned for you between
      <span class="font-weight-500">
        {{ sinceDay | date : localeService.datetimePipeString(locale) }}
      </span>
      and
      <span class="font-weight-500">
        {{ untilDay | date : localeService.datetimePipeString(locale) }}
      </span>
      .
    </span>

    <ng-template #actionsFoundElement>
      <div class="padding-content mt-normal flex-column gap-small">
        <ng-container *ngFor="let planningAction of myPlanningActions">
          <!-- EXPANSION TILE -->
          <ng-container
            *ngTemplateOutlet="
              expansionItem;
              context: { context: this, planningAction: planningAction }
            "
          >
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </ng-template>

  <!-- ############################## MY ASSETS ############################## -->
  <div class="section mt-huge">
    <span>
      My Assets
      <span
        *ngIf="inMyCareAssets.size > 0 || inMyResponsibilityAssets.size > 0"
      >
        ({{ inMyCareAssets.size }} + {{ inMyResponsibilityAssets.size }})
      </span>
    </span>
  </div>

  <span class="mat-body mt-small mb-small padding-content">
    Below you find all assets that are currently not deposited at any location
    (i.e. <span class="font-weight-500 color-black">away assets</span>) and
    either directly in your care or in your responsibility.
  </span>

  <mat-divider></mat-divider>

  <div
    class="mt-small flex-row align-items-center gap-small padding-content mat-body"
  >
    <div class="label bg-in-my-care"></div>
    <span class="nowrap"> in my care </span>
    <span class="flex-grow-1"></span>
    <span class="opacity-50 font-size-12" style="text-align: right">
      asset is booked to me
    </span>
  </div>

  <div
    class="mt-small mb-small flex-row align-items-center gap-small padding-content mat-body"
  >
    <div class="label bg-in-my-responsibility"></div>
    <span class="nowrap"> in my responsibility </span>
    <span class="flex-grow-1"></span>
    <span class="opacity-50 font-size-12" style="text-align: right">
      booked by me to someone/somewhere else
    </span>
  </div>

  <mat-divider></mat-divider>

  <ng-template #myAssetTemplate let-context="context" let-inMy="inMy">
    <div
      class="my-asset-box"
      (click)="onAssetClicked(context.value.myAsset)"
      [class.border-color-in-my-care]="inMy === 'in-my-care'"
      [class.bg-light-in-my-care]="inMy === 'in-my-care'"
      [class.border-color-in-my-responsibility]="
        inMy === 'in-my-responsibility'
      "
      [class.bg-light-in-my-responsibility]="inMy === 'in-my-responsibility'"
    >
      <div class="flex-column ml-small mat-body">
        <span class="uppercase font-size-16 font-weight-500">
          {{ context.value.tenantAction.actionType.name }}
        </span>
        <span class="font-size-12">{{
          context.value.tenantAction.timestamp
            | date : localeService.datetimePipeString(locale)
        }}</span>
        <div class="flex-row font-size-12">
          <ng-container
            *ngIf="
              inMy === 'in-my-care' &&
              context.value.tenantAction.userOid != myOId
            "
          >
            <span>by:&nbsp;</span>
            <lib-user
              variant="phone"
              [oId]="context.value.tenantAction.userOid"
            ></lib-user>
          </ng-container>

          <ng-container *ngIf="inMy === 'in-my-responsibility'">
            <span>to:&nbsp;</span>
            <app-action-details
              fromOrTo="to"
              [action]="context.value.tenantAction"
              variant="phone"
            ></app-action-details>
          </ng-container>
        </div>
      </div>

      <div class="flex-column mat-body">
        <span class="font-weight-500 font-size-16">{{ context.key }}</span>
        <span class="font-size-12">{{ context.value.myAsset[this.properties.get('Name')!.id] }}</span>
        <div
          *ngIf="context.value.myAsset?.currentPlanName"
          class="font-size-12 flex-row"
        >
          <span>plan:&nbsp;</span>
          <span>
            {{ context.value.myAsset?.currentPlanName }}
          </span>
        </div>
      </div>

      <div class="flex-grow-1"></div>

      <div
        *ngIf="context.value.myAsset?.defectState > 1"
        class="flex-row align-items-center gap-tiny"
      >
        <button
          mat-icon-button
          style="background-color: white"
          (click)="showAssetDefectHistory(context.value.myAsset.id, $event)"
        >
          <div
            class="flex-column align-items-center"
            [class.adjust-margin-on-defect-button]="
              context.value.myAsset?.defectState === 3
            "
          >
            <span class="font-weight-500 color-warn font-size-16">D</span>
            <span
              *ngIf="context.value.myAsset?.defectState === 3"
              class="font-size-12 color-warn"
              style="line-height: 12px"
            >
              100
            </span>
          </div>
        </button>
      </div>
    </div>
  </ng-template>

  <div
    *ngIf="
      inMyCareAssets.size > 0 || inMyResponsibilityAssets.size > 0;
      else noMyAwayAssets
    "
    class="mt-normal flex-column gap-normal"
  >
    <ng-container *ngFor="let inMyCare of inMyCareAssets | keyvalue">
      <div class="padding-content">
        <ng-container
          [ngTemplateOutlet]="myAssetTemplate"
          [ngTemplateOutletContext]="{ context: inMyCare, inMy: 'in-my-care' }"
        ></ng-container>
      </div>
    </ng-container>

    <ng-container
      *ngFor="let inMyResponsibility of inMyResponsibilityAssets   | keyvalue"
    >
      <div class="padding-content">
        <ng-container
          [ngTemplateOutlet]="myAssetTemplate"
          [ngTemplateOutletContext]="{
            context: inMyResponsibility,
            inMy: 'in-my-responsibility'
          }"
        ></ng-container>
      </div>
    </ng-container>
  </div>

  <div class="mt-huge"></div>
</div>

<ng-template #noMyAwayAssets>
  <span class="mt-normal padding-content mat-body">
    No relevant assets found.
  </span>
</ng-template>

<!-- QRCODE BUTTON OVERLAY -->
<div class="qrcode-button-overlay">
  <button
    mat-fab
    color="primary"
    style="pointer-events: all"
    (click)="ngScan = true"
  >
    <mat-icon>crop_free</mat-icon>
  </button>
</div>

<!-- SCANNING OVERLAY: MULTIPLE ASSETS SCAN -->
<app-assets-scan
  *ngIf="scanAssets"
  (onClose)="
    onClosePlanStepActionScan(nextAssetsScanData?.planStepAction?.id, $event)
  "
  [inputData]="nextAssetsScanData"
  style="z-index: 1"
></app-assets-scan>

<!-- SCANNING OVERLAY: NEXT GENERATION SCAN -->
<app-ng-scan
  *ngIf="ngScan"
  (onClose)="onCloseNgScan($event)"
  style="z-index: 1"
>
</app-ng-scan>
