import { Component, Inject, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { LOCAL_STORAGE } from '../../common/localStorage';
import { AssetService } from 'projects/shared/src/lib/services/asset.service';
import {
  AssetDefectsQueryArgs,
  AssetDefectsQueryRoot,
  ReportDefectMutationArgs,
  reportDefectMutationRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { firstValueFrom } from 'rxjs';
import { FULL_FRAGMENT_TENANT_DEFECT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantDefect';
import { TenantDefectOutput } from 'projects/shared/src/lib/graphql/output/tenantDefectOutput';
import { PhoneToastService } from '../../services/phone-toast.service';
import { PhoneToastDialogType } from '../../component-dialogs/phone-toast-dialog/phone-toast-dialog.component';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';
import {
  NgScanKeyboardDialogComponent,
  NgScanKeyboardDialogResult,
} from '../../component-helpers/ng-scan/ng-scan-keyboard-dialog/ng-scan-keyboard-dialog.component';
import { Location } from '@angular/common';
import { UIDefectState, uiDefectStates } from './common';
import {
  NgScanNotesDialogComponent,
  NgScanNotesDialogData,
  NgScanNotesDialogResult,
} from '../../component-helpers/ng-scan/ng-scan-notes-dialog/ng-scan-notes-dialog.component';
import {
  ShowDefectsDialogComponent,
  ShowDefectsDialogData,
} from '../../component-dialogs/show-defects-dialog/show-defects-dialog.component';

type Data = {
  asset: any | undefined;
  assetDefects: TenantDefectOutput[];
};

@Component({
  selector: 'app-defect',
  templateUrl: './defect.component.html',
  styleUrls: ['./defect.component.scss'],
})
export class DefectComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scanner') scanner!: NgxScannerQrcodeComponent;
  devices: ScannerQRCodeDevice[] = [];
  cameraIsOn = true;
  activity = false;
  data: Data | undefined;
  readonly defectStates = uiDefectStates;
  selectedDefectState: UIDefectState | undefined;
  defectNotes: string | undefined;

  get selectedDevice() {
    return this.#selectedDevice;
  }
  set selectedDevice(value: ScannerQRCodeDevice | undefined) {
    this.#selectedDevice = value;
    if (value) {
      localStorage.setItem(LOCAL_STORAGE.SELECTED_DEVICE_ID, value.deviceId);

      this.scanner.isBeep = false;
      this.scanner.vibrate = 0;
      this.scanner.playDevice(value.deviceId);
    }
  }

  get canShowDefectHistory(): boolean {
    return !!this.data && this.data.assetDefects.length > 0;
  }

  #selectedDevice: ScannerQRCodeDevice | undefined;
  #lastSuccessfulAssetIdScan: string | undefined;

  constructor(
    private apollo: Apollo,
    public localeService: LocaleService,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    private matDialog: MatDialog,
    private assetService: AssetService,
    private toastService: PhoneToastService,
    private location: Location
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.#startCamera();
  }

  ngOnDestroy(): void {
    this.scanner.stop();
  }

  toggleCamera() {
    if (this.cameraIsOn) {
      this.scanner.stop();
    } else {
      this.#startCamera();
    }

    this.cameraIsOn = !this.cameraIsOn;
    (document.activeElement as HTMLElement)?.blur();
  }

  onClickClose() {
    this.location.back();
  }

  async onEventCode(events: ScannerQRCodeResult[]) {
    for (const id of events.map((x) => x.value)) {
      if (id === this.#lastSuccessfulAssetIdScan) {
        continue; // Avoid multiple reloads due to continues scanning.
      }

      this.#handleAssetId(id);
    }
  }

  onClickKeyboard() {
    const dialog = this.matDialog.open(NgScanKeyboardDialogComponent);

    dialog.afterClosed().subscribe((result: NgScanKeyboardDialogResult) => {
      if (!result) {
        return;
      }

      (document.activeElement as HTMLElement)?.blur();

      this.#handleAssetId(result);
    });
  }

  editNotes() {
    const data: NgScanNotesDialogData = {
      notes: this.defectNotes,
    };

    const dialog = this.matDialog.open(NgScanNotesDialogComponent, {
      data,
      width: '90%',
      maxWidth: 704,
    });

    dialog.afterClosed().subscribe((result: NgScanNotesDialogResult | undefined) => {
      (document.activeElement as HTMLElement)?.blur();

      if (!result) {
        return;
      }

      this.defectNotes = result.notes;
    });
  }

  showDefectRecords() {
    if (!this.data) {
      return;
    }

    const data: ShowDefectsDialogData = {
      assetId: this.data.asset.id,
      assetDefects: this.data?.assetDefects,
    };

    const dialog = this.matDialog.open(ShowDefectsDialogComponent, {
      data,
      width: '90%',
      maxWidth: 704,
    });

    dialog.afterClosed().subscribe(() => {
      (document.activeElement as HTMLElement)?.blur();
    });
  }

  async reportDefect() {
    if (!this.selectedDefectState || !this.defectNotes || !this.data?.asset) {
      return;
    }

    try {
      this.activity = true;
      const variables: ReportDefectMutationArgs = {
        data: {
          assetId: this.data.asset.id,
          comment: this.defectNotes,
          defectStateId: this.selectedDefectState.id,
        },
      };

      await firstValueFrom(
        this.apollo.mutate<reportDefectMutationRoot>({
          mutation: gql`
            mutation ReportDefect($data: TenantDefectInputCreate!) {
              reportDefect(data: $data) {
                id
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );

      this.activity = false; // IMPORTANT
      this.selectedDefectState = undefined;
      this.defectNotes = undefined;
      this.#handleAssetId(variables.data.assetId);
    } catch (error) {
      this.activity = false;
      this.toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    }
  }

  #startCamera() {
    const savedSelectedDeviceId = localStorage.getItem(LOCAL_STORAGE.SELECTED_DEVICE_ID);

    this.scanner.start((devices: ScannerQRCodeDevice[] | undefined) => {
      if (typeof devices === 'undefined' || devices.length === 0) {
        return;
      }

      this.devices = devices;

      if (savedSelectedDeviceId) {
        this.selectedDevice = this.devices.find((x) => x.deviceId === savedSelectedDeviceId);
      } else {
        // First run. Chose the first device.
        this.selectedDevice = this.devices[0];
      }
    });
  }

  async #handleAssetId(assetId: string) {
    if (this.activity) {
      return;
    }

    this.activity = true;
    this.data = undefined;
    try {
      const asset = await this.#loadAssetData(assetId);
      const assetDefects = await this.#loadAssetDefects(assetId);
      this.data = {
        asset,
        assetDefects,
      };
      if (asset?.id) {
        this.#lastSuccessfulAssetIdScan = asset.id;
      }
    } catch (error) {
      this.toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    } finally {
      this.activity = false;
    }
  }

  async #loadAssetData(tenantAssetId: string): Promise<any> {
    return await this.assetService.fetch(tenantAssetId, 'network-only');
  }

  async #loadAssetDefects(tenantAssetId: string): Promise<TenantDefectOutput[]> {
    const variables: AssetDefectsQueryArgs = {
      assetId: tenantAssetId,
    };

    const result = await firstValueFrom(
      this.apollo.query<AssetDefectsQueryRoot>({
        query: gql`
          ${FULL_FRAGMENT_TENANT_DEFECT}
          query AssetsDefects($assetId: String!) {
            assetDefects(assetId: $assetId) {
              ...FullFragmentTenantDefect
            }
          }
        `,
        variables,
        fetchPolicy: 'network-only',
      })
    );

    return result.data.assetDefects;
  }
}
