<mat-toolbar color="primary">
  <div class="header-line">
    <div
      class="container flex-row justify-content-space-between align-items-center"
    >
      <span>Global Asset Tracker</span>
      <button mat-icon-button (click)="onClickMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
