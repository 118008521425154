<span *ngIf="!fromOrTo || !action; else allGood">na</span>

<ng-template #allGood>
  <ng-container *ngIf="fromOrTo === 'from' && action">
    <lib-location
      *ngIf="action.fromLocationId"
      [locationId]="action.fromLocationId"
    ></lib-location>
    <lib-user
      *ngIf="action.fromUserOid"
      [variant]="variant"
      [oId]="action.fromUserOid"
    ></lib-user>
    <span *ngIf="action.fromMail">{{ action.fromMail }}</span>
    <span *ngIf="action.fromOther">{{ action.fromOther }}</span>
  </ng-container>

  <ng-container *ngIf="fromOrTo === 'to' && action">
    <lib-location
      *ngIf="action.toLocationId"
      [locationId]="action.toLocationId"
    ></lib-location>
    <lib-user
      *ngIf="action.toUserOid"
      [variant]="variant"
      [oId]="action.toUserOid"
    ></lib-user>
    <span *ngIf="action.toMail">{{ action.toMail }}</span>
    <span *ngIf="action.toOther">{{ action.toOther }}</span>
  </ng-container>
</ng-template>
