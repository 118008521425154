import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { ActionFrom, actionFroms } from 'projects/shared/src/lib/graphql/enums/actionFrom';
import { ActionTo, actionTos } from 'projects/shared/src/lib/graphql/enums/actionTo';
import { ActionType } from 'projects/shared/src/lib/graphql/enums/actionType';
import { ActionTypeOutput } from 'projects/shared/src/lib/graphql/output/actionTypeOutput';
import { ActionLogicService } from 'projects/shared/src/lib/services/action-logic.service';
import { v4 } from 'uuid';

// TODO: Add more data later to limit the options for the user.
export type NgScanFromOrToDialogData = {
  actionType: ActionTypeOutput;
  direction: 'from' | 'to';
  selectedFromType: ActionFrom | undefined;
  fromDetails: string | undefined;
  selectedToType: ActionTo | undefined;
  toDetails: string | undefined;
};

export type NgScanFromOrToDialogResult = {
  selectedFromType: ActionFrom | undefined;
  selectedToType: ActionTo | undefined;
  fromDetails: string | undefined;
  toDetails: string | undefined;
};

@Component({
  selector: 'app-ng-scan-from-or-to-dialog',
  templateUrl: './ng-scan-from-or-to-dialog.component.html',
  styleUrls: ['./ng-scan-from-or-to-dialog.component.scss'],
})
export class NgScanFromOrToDialogComponent implements OnInit {
  account: AccountInfo | undefined;
  fromTypes = actionFroms;
  toTypes = actionTos;
  selectedFromType: ActionFrom | undefined;
  fromDetails: string | undefined;
  selectedToType: ActionTo | undefined;
  toDetails: string | undefined;
  readonly uuid1 = v4();
  readonly uuid2 = v4();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: NgScanFromOrToDialogData,
    private _dialogRef: MatDialogRef<NgScanFromOrToDialogComponent>,
    private _actionLogicService: ActionLogicService,
    private _msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.account = this._msalService.instance.getAllAccounts()[0];

    this.#setFromAndToTypes();

    if (this.data.direction === 'from') {
      this.selectedFromType = this.data.selectedFromType;
      this.fromDetails = this.data.fromDetails;

      if (!this.selectedFromType) {
        // Select the first in the list.
        this.selectedFromType = Array.from(this.fromTypes)[0][0];
      }
    } else {
      this.selectedToType = this.data.selectedToType;
      this.toDetails = this.data.toDetails;

      if (!this.selectedToType) {
        // Select the first in the lsit.
        this.selectedToType = Array.from(this.toTypes)[0][0];
      }
    }
  }

  onClickOk() {
    const result: NgScanFromOrToDialogResult = {
      selectedFromType: this.selectedFromType,
      selectedToType: this.selectedToType,
      fromDetails: this.fromDetails,
      toDetails: this.toDetails,
    };

    this._dialogRef.close(result);
  }

  me(direction: 'from' | 'to') {
    if (!this.account?.idTokenClaims?.oid) {
      return;
    }

    if (direction === 'from') {
      this.fromDetails = this.account.idTokenClaims.oid;
    } else {
      this.toDetails = this.account.idTokenClaims.oid;
    }
  }

  #setFromAndToTypes() {
    this.fromTypes = this._actionLogicService.determineValidFromTypes(this.data.actionType);
    this.toTypes = this._actionLogicService.determineValidToTypes(this.data.actionType);
  }
}
