import { Injectable } from '@angular/core';
import { BaseSelectionService } from 'projects/shared/src/lib/services/base-selection.service';

@Injectable({
  providedIn: 'root',
})
export class SelectionService extends BaseSelectionService {
  // // #region Tenant Related
  // selectedTenant: TenantOutput | undefined;
  // get hasAnyUserRoles(): boolean {
  //   const userRoles = this._myUser?.userRoles;
  //   if (typeof userRoles === 'undefined'
  //     || userRoles == null
  //     || !Array.isArray(userRoles)
  //     || userRoles.length === 0
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }
  // get hasMultiTenantAccess(): boolean {
  //   if (!this.selectedTenant) {
  //     return false;
  //   }
  //   const userRoles = this._myUser?.userRoles;
  //   if (typeof userRoles === 'undefined'
  //     || userRoles == null
  //     || !Array.isArray(userRoles)
  //     || userRoles.length === 0
  //   ) {
  //     return false;
  //   }
  //   const distinctTenantIds = [... new Set(userRoles.filter(x => typeof x.tenantId !== 'undefined').map(x => x.tenantId))];
  //   return distinctTenantIds.length > 1;
  // }
  // // #endregion Tenant Related
  // private _myUser: UserOutput | undefined;
  //constructor() {}
}
