<ngx-scanner-qrcode
  #action="scanner"
  (event)="onEventQrCode($event)"
></ngx-scanner-qrcode>

<div class="control-overlay" [class.readable-background]="!cameraIsOn">
  <div *ngIf="activity" class="activity">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>

  <!-- ################# AREA 1: TOP #################-->
  <div class="row-1 form-field-density-2">
    <button
      mat-icon-button
      color="accent"
      class="mr-tiny"
      (click)="toggleCamera()"
    >
      <span class="material-symbols-outlined">
        {{ cameraIsOn ? "pause" : "play_arrow" }}
      </span>
    </button>
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      color="accent"
      class="width-100 camera-selection"
    >
      <mat-select [(ngModel)]="selectedDevice" class="color-accent-400">
        <mat-option *ngFor="let device of devices" [value]="device">{{
          device.label
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-icon-button
      color="accent"
      class="ml-tiny"
      (click)="debugMode = !debugMode"
    >
      <mat-icon fontSet="material-symbols-outlined">bug_report</mat-icon>
    </button>
  </div>

  <!-- ################# AREA 2: MIDDLE ################# -->
  <div class="row-2-debug" *ngIf="debugMode">
    <span *ngFor="let debugEvent of debugEvents">
      {{ debugEvent }}
    </span>
  </div>

  <div class="row-2" *ngIf="!debugMode">
    <!-- PART 1: Show scanned Assets -->
    <ng-container *ngFor="let ngScanAsset of ngScanAssets | keyvalue">
      <div class="asset-row">
        <button
          mat-icon-button
          color="accent"
          (click)="deleteAsset(ngScanAsset.value.id)"
        >
          <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
        </button>

        <div class="flex-column" style="margin-top: 14px">
          <span
            [ngClass]="
              (ngScanAssetsLabels.get(ngScanAsset.value.id) ?? []).length > 0 ||
              selectedMode === 'NONE'
                ? 'color-warn-400'
                : 'color-accent-400 '
            "
            class="flex-row align-items-center gap-small"
          >
            <span>{{ ngScanAsset.key }}</span>
            <div
              *ngIf="ngScanAssetsLabels.get(ngScanAsset.value.id)"
              class="mat-body label-warning"
            >
              {{ ngScanAssetsLabels.get(ngScanAsset.value.id) }}
            </div>
          </span>

          <div
            *ngFor="let plan of ngScanAsset.value.plans"
            class="flex-row align-items-center gap-small"
          >
            <span
              *ngIf="ngScanAsset.value.asset?.currentPlanName === plan.name"
              class="material-symbols-outlined fill color-green font-size-14"
            >
              circle
            </span>
            <span class="mat-body">{{ plan.name }}</span>
            <span class="mat-body">
              ({{
                plan.planStart | date : localeService.datePipeString(locale)
              }}
              -
              {{ plan.planEnd | date : localeService.datePipeString(locale) }}
              )
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Part 2: Show assets of a selected plan and their status -->
    <ng-container *ngIf="selectedExtPlanDetail">
      <mat-divider></mat-divider>
      <div class="flex-column align-items-end gap-small">
        <div
          *ngFor="let scannedAsset of selectedExtPlanDetail.scannedAssets"
          class="flex-row align-items-center gap-small"
        >
          <mat-icon
            fontSet="material-symbols-outlined"
            [class.color-accent-400]="
              !scannedAsset.assetBooked &&
              ngScanAssets.has(scannedAsset.assetId)
            "
          >
            {{
              scannedAsset.assetBooked || ngScanAssets.has(scannedAsset.assetId)
                ? "check"
                : "space_bar"
            }}
          </mat-icon>
          <span class="prevent-select">{{ scannedAsset.assetId }}</span>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- ################# AREA 3: BOTTOM ACTION BUTTONS ################# -->
  <div class="row-3">
    <div class="keyboard">
      <button mat-icon-button color="accent" (click)="onClickKeyboard()">
        <mat-icon fontSet="material-symbols-outlined">keyboard</mat-icon>
      </button>
    </div>
    <div class="plan-mode">
      <mat-radio-group [(ngModel)]="selectedMode">
        <mat-radio-button
          value="REALTIME"
          [disabled]="!evaluationResults?.isRealtimeBookModeAvailable"
        >
          <span class="mr-normal"> REALTIME </span>
        </mat-radio-button>
        <mat-radio-button
          value="PLAN"
          [disabled]="!evaluationResults?.isPlanBookModeAvailable"
        >
          <span> PLAN </span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="info">
      <button
        mat-icon-button
        color="accent"
        (click)="showMore = !showMore"
        [disabled]="!canShowMore"
      >
        <mat-icon fontSet="material-symbols-outlined">unfold_more</mat-icon>
      </button>
    </div>
  </div>

  <!-- ################# AREA 4: BOTTOM ################# -->
  <div class="row-4">
    <!-- OPTION 1: REALTIME SELECTIONS -->
    <div
      *ngIf="
        evaluationResults?.isRealtimeBookModeAvailable &&
        selectedMode === 'REALTIME'
      "
      class="mat-body flex-column gap-small form-field-density-2"
    >
      <!-- More Box ??? -->
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mt-tiny"
      >
        <mat-label>Action</mat-label>
        <mat-select [(ngModel)]="realtimeConfig.selectedActionType" required>
          <mat-select-trigger>
            <span class="font-size-14 uppercase">{{
              realtimeConfig.selectedActionType?.name
            }}</span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let actionType of realtimeConfig.validNextActionTypes"
            [value]="actionType"
          >
            <span class="font-size-14 uppercase">{{ actionType.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mt-tiny"
      >
        <mat-label>From</mat-label>
        <input
          matInput
          type="text"
          [ngModel]="realtimeConfig.fromDetailsCalculated"
          readonly
          placeholder="Please edit ..."
          required
          [disabled]="!this.realtimeConfig.selectedActionType"
        />
        <button
          matSuffix
          mat-icon-button
          (click)="realtimeEdit('from')"
          [disabled]="true"
        >
          <span class="material-symbols-outlined"> edit </span>
        </button>
      </mat-form-field>

      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mt-tiny"
      >
        <mat-label>To</mat-label>
        <input
          matInput
          type="text"
          [ngModel]="realtimeConfig.toDetailsCalculated"
          readonly
          placeholder="Please edit ..."
          required
          [disabled]="!this.realtimeConfig.selectedActionType"
        />
        <button
          matSuffix
          mat-icon-button
          (click)="realtimeEdit('to')"
          [disabled]="!this.realtimeConfig.selectedActionType"
        >
          <span class="material-symbols-outlined"> edit </span>
        </button>
      </mat-form-field>

      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mt-tiny"
      >
        <mat-label>Notes</mat-label>
        <input
          matInput
          type="text"
          [ngModel]="realtimeConfig.description"
          readonly
          placeholder="Add optional notes ..."
        />
        <button matSuffix mat-icon-button (click)="realtimeEditNotes()">
          <span class="material-symbols-outlined"> edit </span>
        </button>
      </mat-form-field>
    </div>

    <!-- OPTION 2: PLAN SELECTIONS -->
    <div
      *ngIf="
        evaluationResults?.isPlanBookModeAvailable && selectedMode === 'PLAN'
      "
      class="mat-body flex-column gap-small form-field-density-2"
    >
      <!-- More Box -->
      <div *ngIf="showMore" class="more-box">
        <div class="flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500 color-primary-400">PLAN:</span>
          <span>{{ selectedExtPlan?.plan?.name }}</span>
        </div>

        <div class="flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500"># OF STEPS:</span>
          <span>{{ selectedExtPlan?.plan?.planSteps?.length ?? 0 }}</span>
        </div>

        <div
          *ngIf="selectedExtPlan?.plan?.description"
          class="flex-row align-items-center gap-tiny overflow-x-hidden"
        >
          <span class="font-weight-500">NOTES:</span>
          <span>{{ selectedExtPlan?.plan?.description }}</span>
        </div>

        <div
          class="mt-normal flex-row align-items-center gap-tiny overflow-x-hidden"
        >
          <span class="font-weight-500 color-primary-400">STEP:</span>
          <span>{{ selectedExtPlanDetail?.planStep?.name }}</span>
        </div>
        <div class="flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500"># OF ACTIONS:</span>
          <span>
            {{ selectedExtPlanDetail?.planStep?.planStepActions?.length ?? 0 }}
          </span>
        </div>
        <div
          *ngIf="selectedExtPlanDetail?.planStep?.description"
          class="flex-row align-items-center gap-tiny overflow-x-hidden"
        >
          <span class="font-weight-500">NOTES:</span>
          <span>{{ selectedExtPlanDetail?.planStep?.description }}</span>
        </div>

        <div
          class="mt-normal flex-row align-items-center gap-tiny overflow-x-hidden"
        >
          <span class="font-weight-500 color-primary-400">ACTION:</span>
          <span class="uppercase">
            {{ selectedExtPlanDetail?.planStepAction?.actionType?.name }}
          </span>
        </div>

        <div
          *ngIf="
            selectedExtPlanDetail?.planStepAction
              ?.transitLocationId as transitLocationId
          "
          class="flex-row align-items-center gap-tiny overflow-x-hidden"
        >
          <span class="font-weight-500">HANDOVER LOCATION:</span>
          <lib-location [locationId]="transitLocationId"></lib-location>
        </div>

        <div class="flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500">FROM:</span>
          <app-action-details
            fromOrTo="from"
            [action]="selectedExtPlanDetail?.planStepAction"
            class="ellipsis"
          >
          </app-action-details>
        </div>

        <div class="flex-row align-items-center gap-tiny overflow-x-hidden">
          <span class="font-weight-500">TO:</span>
          <app-action-details
            fromOrTo="to"
            [action]="selectedExtPlanDetail?.planStepAction"
            class="ellipsis"
          >
          </app-action-details>
        </div>

        <div
          *ngIf="selectedExtPlanDetail?.planStepAction?.description"
          class="flex-row align-items-center gap-tiny overflow-x-hidden"
        >
          <span class="font-weight-500">NOTES:</span>
          <span>{{ selectedExtPlanDetail?.planStepAction?.description }}</span>
        </div>
      </div>

      <!-- Plan Selection -->
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mt-tiny"
      >
        <mat-label>Plan</mat-label>
        <mat-select [(ngModel)]="selectedExtPlan">
          <mat-select-trigger>
            <span class="font-size-14">{{ selectedExtPlan?.plan?.name }}</span>
          </mat-select-trigger>
          <mat-option
            *ngFor="let extPlan of evaluationResults?.availableExtPlans"
            [value]="extPlan"
          >
            <span class="font-size-14">{{ extPlan.plan.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Plan Step Action Selection -->
      <mat-form-field
        subscriptSizing="dynamic"
        appearance="outline"
        class="mt-tiny"
      >
        <mat-label>Step & Action</mat-label>
        <mat-select [(ngModel)]="selectedExtPlanDetail">
          <mat-select-trigger>
            <div class="font-size-14 flex-row align-items-center gap-small">
              <span>{{ selectedExtPlanDetail?.planStep?.name }}</span>
              -
              <span class="uppercase">
                {{ selectedExtPlanDetail?.planStepAction?.actionType?.name }}
              </span>
              -
              <span>
                {{
                  selectedExtPlanDetail?.planStepAction?.date
                    | date : localeService.datetimePipeString(locale)
                }}
              </span>
            </div>
          </mat-select-trigger>
          <mat-option
            *ngFor="let detail of selectedExtPlan?.details"
            [value]="detail"
          >
            <div class="font-size-14 flex-row align-items-center gap-small">
              <span>{{ detail.planStep.name }}</span>
              -
              <span class="uppercase">
                {{ detail.planStepAction.actionType?.name }}
              </span>
              -
              <span>
                {{
                  detail.planStepAction.date
                    | date : localeService.datetimePipeString(locale)
                }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- PLAN DURATION CHECK -->
    <mat-form-field
      appearance="outline"
      subscriptSizing="dynamic"
      class="mt-tiny padding-content form-field-density-2"
    >
      <mat-label>Plan Search Scope</mat-label>
      <mat-date-range-input [rangePicker]="planCheckDurationPicker">
        <input
          matStartDate
          placeholder="Start date"
          readonly
          [(ngModel)]="planCheckStart"
        />
        <input
          matEndDate
          placeholder="End date"
          readonly
          [(ngModel)]="planCheckEnd"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="planCheckDurationPicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker
        #planCheckDurationPicker
        [touchUi]="true"
        (closed)="onPlanCheckDatePickerClosed()"
      ></mat-date-range-picker>
    </mat-form-field>

    <!-- CANCEL AND BOOK -->
    <div class="grid-2">
      <button mat-stroked-button (click)="onClickClose()" [disabled]="activity">
        CANCEL
      </button>
      <button
        mat-flat-button
        color="accent"
        (click)="onClickBook()"
        [disabled]="
          activity ||
          !canBook ||
          selectedMode === 'NONE' ||
          ngScanAssets.size === 0 ||
          (selectedMode === 'PLAN' && !selectedExtPlanDetail) ||
          ngScanAssets.size !== okNgScanAssets.size ||
          (selectedMode === 'REALTIME' &&
            (!realtimeConfig.selectedActionType ||
              !realtimeConfig.selectedFromType ||
              !realtimeConfig.selectedToType ||
              !realtimeConfig.fromDetails ||
              !realtimeConfig.toDetails))
        "
      >
        <div class="flex-row gap-small"></div>
        <span> BOOK </span>
      </button>
    </div>
  </div>
</div>
