import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Apollo, gql } from 'apollo-angular';
import {
  AssetDefectsQueryArgs,
  AssetDefectsQueryRoot,
} from 'projects/shared/src/lib/graphql/crud/tenantDefect';
import { FULL_FRAGMENT_TENANT_DEFECT } from 'projects/shared/src/lib/graphql/fragments/fullFragmentTenantDefect';
import { TenantDefectOutput } from 'projects/shared/src/lib/graphql/output/tenantDefectOutput';
import { LocaleService } from 'projects/shared/src/lib/services/locale.service';
import { firstValueFrom } from 'rxjs';
import { PhoneToastService } from '../../services/phone-toast.service';
import { PhoneToastDialogType } from '../phone-toast-dialog/phone-toast-dialog.component';
import { CatchError } from 'projects/shared/src/lib/classes/catch-error';


export type ShowDefectsDialogData = {
  assetId: string;
  assetDefects?: TenantDefectOutput[];
};

@Component({
  selector: 'app-show-defects-dialog',
  templateUrl: './show-defects-dialog.component.html',
  styleUrls: ['./show-defects-dialog.component.scss'],
})
export class ShowDefectsDialogComponent implements OnInit {
  assetDefects: TenantDefectOutput[] = [];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowDefectsDialogData,
    @Inject(MAT_DATE_LOCALE) public locale: string,
    public localeService: LocaleService,
    private apollo: Apollo,
    private toastService: PhoneToastService
  ) {}

  ngOnInit(): void {
    if (typeof this.data.assetDefects !== 'undefined') {
      this.assetDefects = this.data.assetDefects.sortBy((x) => x.timestamp, 'desc');
    } else {
      this.#loadData();
    }
  }

  async #loadData() {
    const variables: AssetDefectsQueryArgs = {
      assetId: this.data.assetId,
    };

    this.loading = true;
    try {
      const result = await firstValueFrom(
        this.apollo.query<AssetDefectsQueryRoot>({
          query: gql`
            ${FULL_FRAGMENT_TENANT_DEFECT}
            query AssetDefects($assetId: String!) {
              assetDefects(assetId: $assetId) {
                ...FullFragmentTenantDefect
              }
            }
          `,
          variables,
          fetchPolicy: 'network-only',
        })
      );
      this.assetDefects = result.data.assetDefects;
    } catch (error) {
      this.toastService.show(PhoneToastDialogType.Error, new CatchError(error).message);
    } finally {
      this.loading = false;
    }
  }
}
