import { Component } from '@angular/core';

@Component({
  selector: 'app-expansion-tile-content',
  templateUrl: './expansion-tile-content.component.html',
  styleUrls: ['./expansion-tile-content.component.scss']
})
export class ExpansionTileContentComponent {

}
